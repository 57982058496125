import { MetaContentFragment } from '../common/MetaContentHead'

export const AnimatedTilesFragment = `
  heading
  subHeading
`

export const CarouselItemCardFragment = `
    image {
      url
      description
    }
    cloudinaryImage
    statement
    primaryLabel
    secondaryLabel
`

export const LargePhotoItemFragment = `
  photo {
    url
    title
    description
  }
  cloudinaryPhoto
  textBelow {
    json
  }
`

export const CarouselSectionFragment = `
  heading
  titleStyle
  teaserCopy {
    json
  }
  itemsCollection {
    items {
      __typename
      ... on ComponentCarouselItem {
        ${CarouselItemCardFragment}
      }
      ... on ComponentLargePhotoCarouselItem {
        ${LargePhotoItemFragment}
      }
    }
  }
  itemsPerPage
  arrowStyle
  dotsStyle
  showNextMobile
  sectionId
`

export const CompareTableFragment = `
  foraColumnHeading
  othersColumnHeading
  rowsCollection {
    items {
      internalLabel
      title
      foraValue
      othersValue
    }
  }
  ctaText
  ctaUrl
  sectionId
`

export const SignUpFormComponentFragment = `
formType
formCta
header
internalLabel
linkedFrom
subContent {
  json
}
textContent {
  json
}
`

export const ComponentGlobalBannerFragment = `
  sys {
    publishedAt
  }
  internalLabel
  content {
    json
  }
  isActive
  backgroundColor
  expirationDate
`

export const ComponentImageFragment = `
  __typename
  internalTitle
  file {
    width
    height
		url
    description
	}
  cloudinaryImage
  altText
  imageCredit
`

export const ComponentImageGallerySlideItemFragment = `
  title
  description {
    json
  }
  ctaText
  featuredImage {
    url
    description
    contentType
    height
    width
  }
  cloudinaryFeaturedImage
  imagesCollection {
    items {
      url
    }
  }
  cloudinaryImages
  url
`

export const ComponentImageGalleryCarouselFragment = `
  title
  imageGallerySlideItemsCollection {
    items {
      ${ComponentImageGallerySlideItemFragment}
    }
  }
`

export const ComponentThreeIconSectionWithPictureFragment = `
	title
	headerImage {
    width
    height
		url
    description
	}
	imageColumnOne {
    width
    height
		url
    description
	}
	imageColumnTwo {
    width
    height
    url
    description
	}
	imageColumnThree {
    width
    height
    url
    description
	}
  textColumnOne {
		json
	}
  textColumnTwo {
		json
	}
  textColumnThree {
		json
	}
	ctaText
	ctaUrl
  sectionId
  urlTarget
  animate
  separator {
    url
    description
  }
  separatorMobileDisplay
  bgColor
`

export const FullWidthHeroFragment = `
  title
  showForaLogo
  lightText
  rotatingText
  image {
    contentType
    url
    description
    width
  }
  cloudinaryAsset
  mobileCloudinaryAsset
  mobileImage {
    contentType
    url
    description
    width
  }
  bottomText
  buttonCtaText
  buttonCtaUrl
  urlTarget
  fallbackImage {
    url
  }
  fullHeroSubCopy: subCopy
  sectionId
  showAverageRating
  cloudinaryFallbackImage
`

export const CtaBannerFragment = `
  __typename
  heading
  titleStyle
  teaserCopy {
    json
  }
  ctaText
  ctaDestination
  urlTarget
  zapierId
  includeForm
  formType
  successText
`

export const ComponentsFeaturedAdvisorsFragment = `
title
titleStyle
teaserCopy {
  json
}
ctaTarget
ctaText
showCta
advisorsCollection {
  items {
    title
    slug
    image {
      url
      description
    }
    cloudinaryImage
    expertise
  }
}
`

export const componentFeaturedArticlesFragment = `
internalLabel
articleCategory
`

export const FeaturedGuidesFragment = `
title
titleStyle
teaserCopy {
  json
}
guidesCollection(limit: 20) {
  items {
    title
    seoSlug
    slugId
    slug
    image {
      url
      description
    }
    advisor {
      title
      slug
      advisorVariant
      image {
        url
        description
      }
      cloudinaryImage
      expertise
    }
  }
  
}
`

export const ComponentsFeaturedGuidesFragment = `
title
titleStyle
teaserCopy {
  json
}
ctaTarget
ctaText
showCta
guidesCollection(limit: 20) {
  items {
    title
    seoSlug
    slugId
    slug
    cloudinaryImage
    image {
      url
      description
    }
    advisor {
      title
      slug
      advisorVariant
      image {
        url
        description
      }
      cloudinaryImage
      expertise
    }
  }
  
}
`

export const FeaturedHotelsFragment = `
teaserCopy {
  json
}
title
display
showTitle
headerAlignment
hotelsCollection {
  items {
  	title
    cloudinaryImage
	  image {
		  url
      description
	  }
	  description {
		  json
	  }
    heroImage
	  perks
    slug
    generateLandingPage
    destinationCollection(limit:1) {
      items {
        __typename
      }
    }
  }
}
showControls
`

export const FeaturedPartnersFragment = `
  name
  slug
  logo {
    url
    description
    title
    width
    height
  }
`
export const HeaderBlockFragment = `
  title
  textContent {
		json
	}
  teaserCopy {
		json
	}
  ctaText
  ctaUrl
  ctaTheme
  sectionId
  borders
  icon {
    url
  }
  cloudinaryIcon
  footNote
  secondaryCtaText
  secondaryCtaUrl
  secondaryUrlTarget
`

export const HeaderBlockWithImageFragment = `
  title
  titleStyle
  image { 
    url
    description
  }
  cloudinaryImage
`

export const HeroBlockFragment = `
  title
  titleStyle
  image {
    url
    description
  }
  cloudinaryImage
  teaserCopy {
		json
	}
  showFormAsPrimaryCta
  primaryCtaLinkText
  primaryCtaLinkUrl
  secondaryCtaLinkText
  secondaryCtaLinkUrl
  primaryUrlTarget
  secondaryUrlTarget
  showSignUpFormAsPrimaryCta
  subFormHeader
  subFormCopy {
    json
  }
`

export const ImageSideBySideFragment = `
  __typename
  cloudinaryImageOne
  cloudinaryImageTwo
  imageOne {
    width
    height
		url
    description
	}
  imageTwo {
    width
    height
		url
    description
	}
  caption
`

export const LearnMoreBlockFragment = `
	title
  titleStyle
  teaserCopy {
    json
  }
	imageColumnOne {
    width
    height
		url
    description
  }
  cloudinaryImageColumnOne
	imageColumnTwo {
    width
    height
    url
    description
	}
  cloudinaryImageColumnTwo
	imageColumnThree {
    width
    height
    url
    description
	}
  cloudinaryImageColumnThree
  textColumnOne {
		json
	}
  textColumnTwo {
		json
	}
  textColumnThree {
		json
	}
	ctaText
	ctaUrl
  isCompact
  sectionId
  solidBackground
`

export const EntryPartnerFragment = `
  sys {
    id
  }
  logo {
   title
   url
   description
   height
   width
  }
  cloudinaryLogo
  cardCopy {
   json
  }
  partnerType
  slug
  hasDetailPage
  hideFromPartnerGrid
`

export const PartnerGridFragment = `
  sys {
    id
  }
  heading
  headingSharedState
  titleStyle
  subheading {
    json
  }
  subheadingSharedState {
    json
  }
`

export const PerksListFragment = `
	title
  titleStyle
	listItems
  cloudinaryImage
  cloudinaryIconImage
	image {
		url
    description
	}
	iconImage {
    url
    description
	}
`

export const PictureSectionBannerFragment = `
  cloudinaryPicture
  cloudinaryMobilePicture
  cardSize
  title
`

export const PressBannerFragment = `
title
applyBackground
redirectSlug
logoSize
cloudinaryLogos
logosCollection(limit:12) {
  items {
    title
    url
    description
    height
    width
  }
}
`

export const QuestionFragment = `
  title  
  answer {
    json
  }
`

export const QuestionGroupFragment = `
  title
  titleStyle
  questionsCollection {
    items {
      ${QuestionFragment}
    }
  }
  automaticallyExpandQuestions
  sectionId
`

export const SpacerFragment = `
  paddingTop
  paddingTopMobile
  paddingBelow
  paddingBelowMobile
  hasDivider
  icon {
    url
    width
    height
    description
  }
  cloudinaryIcon
  `

export const SubscriptionCardFragment = `
  heading
  titleStyle
  teaserCopy {
    json
  }
  ctaText
  ctaUrl
  urlTarget
  textOnRight
  sectionId
  bulletPoints
  annualIsLimited
  annualOriginalPrice
  annualDiscountPrice
  annualPriceDetails
  localizationFieldPerYear
  localizationFieldAnnual
  annualFeaturedText
  monthlyIsLimited
  monthlyOriginalPrice
  monthlyDiscountPrice
  monthlyPriceDetails
  localizationFieldPerMonth
  localizationFieldMonthly
  monthlyFeaturedText
  showMultiplePricingModels
`

export const FormsFragment = `
  __typename
  internalLabel
  formId
  sectionId
  typeformPopOutUrl
  typeformUrl
  zapierId
  title
  titleSharedState
  teasercopy
  subcopy
  subcopySharedState
  buttonText
  buttonTextSharedState
  successText
  style
  subscriptionCard
  {
    ...on ComponentSubscriptionCard
    {
      ${SubscriptionCardFragment}
    }
  }
`

export const TeamCardFragment = `
  name
  title
  cloudinaryImage
  image {
    url
    description
  }
`

export const TeamSectionFragment = `
  title
  titleStyle
  teamMembersCollection {
    items {
      ${TeamCardFragment}
    }
  }
`

export const TextSectionFragment = `
  __typename
  content {
    json
  }
  alignCenter
  sectionId
`

export const ComponentImageHeaderSubFragment = `
  header
  image {
    url
    width
    height
    description
  }
  cloudinaryImage
  subheading
  titleStyle
`

export const TwoColumnCTAFragment = `
  title
  titleStyle
  textBlockLeft {
    json
  }
  advisor {
    title
    slug
    advisorVariant
    image {
      url
      description
      width
      height
    }
    cloudinaryImage
    expertise
  }
  advisorDescription
  yMargin
  mobileAlignment
  image {
    url
    description
    contentType
    height
    width
  }
  cloudinaryImage
  buttonText
  buttonUrl
  buttonTheme
  urlTarget
  backgroundColor
  contentColumn 
  textUnderneathButton {
    json
  }
  formId
  sectionId
  cardSize
  perksCollection (limit: 12) {
    items {
      ...on ComponentImageHeaderSub {
        ${ComponentImageHeaderSubFragment}
      }
    }
  }
  titleIcon {
    url
    description
  }
  cloudinaryTitleIcon
`

export const TwoColumnTextSectionFragment = `
	title
  emphasizedCopy {
    json
  }
  normalCopy {
    json
  }
  emphasizedColumn
  sectionId
`

export const TypeformEmbedFragment = `
sectionId
form
typeformFallbackUrl
`

export const VideoContentBlockFragment = `
  heading
  titleStyle
  copy {
    json
  }
  video {
    url
    description
  }
  cloudinaryVideo
  mobilePreviewImage
  mobileMediaAsset
  autoplay
  muted
  loop
  fallbackImage {
    url
    description
  }
  cloudinaryFallbackImage
  sectionId
`

export const HotelFragment = `
	title
	image {
		url
    description
	}
  cloudinaryImage
	description {
		json
	}
	perks
  slug
`

export const SubNavBarFragment = `
  navItemsCollection {
    items {
      label
      url
      newWindow
    }
  }
`

export const TestimonialCardFragment = `
sys {
  id
}
  testimonialSource
  aboutFora
  testimonial {
    json
  }
  submitterFirstName
  submitterLastName
  submitterImage {
    description 
    url
  }
  cloudinarySubmitterImage
  submitterEmail
  dateSubmitted
  advisor {
    title
    slug
    firstName
    lastName
    advisorVariant
    image {
      url
      description
    }
    cloudinaryImage
    expertise
  }
  destinationsSummary {
    json
  }
  display
  stars
`

export const TestimonialCarouselFragment = `
  heading
  testimonialsCollection(limit: 50) {
    items {
      ${TestimonialCardFragment}
    }
  }
  sectionId
`

export const ComponentButtonCtaFragment = `
  internalTitle
  buttonOneText
  buttonOneStyle
  buttonOneUrl
  buttonOneUrlTarget
  buttonTwoText
  buttonTwoStyle
  buttonTwoUrl
  buttonTwoUrlTarget
`

export const PartnersBannerFragment = `
logosCollection(limit:12) {
  items {
    title
    url
    description
    height
    width
  }
}
showViewMoreButton
`

export const ComponentInfoGridFragment = `
  header
  headerSubCopy {
    json
  }
  whatWeRequire
  ctaText
  ctaUrl
  sectionId
  itemsCollection(limit: 12) {
    items {
      ...on ComponentImageHeaderSub {
        ${ComponentImageHeaderSubFragment}
      }
    }
  }
`

export const ComponentDataItemFragment = `
  title
  number
  icon {
    url
    width
    height
    description
  }
  cloudinaryIcon
`

export const ComponentDataBannerFragment = `
  title
  itemsCollection(limit: 6) {
    items {
      ...on ComponentDataItem {
        ${ComponentDataItemFragment}
      }
    }
  }
`

export const ComponentWhatYouGetCardFragment = `
  headline
  subHead
  image {
    url
    width
    height
    description
    contentType
  }
  cloudinaryImage
  iconOne {
    url
    width
    height
    description
  }
  cloudinaryIconOne
  iconTwo {
    url
    width
    height
    description
  }
  cloudinaryIconTwo
  iconThree {
    url
    width
    height
    description
  }
  cloudinaryIconThree
  iconFour {
    url
    width
    height
    description
  }
  cloudinaryIconFour
  bulletOne
  bulletTwo
  bulletThree
  bulletFour
  buttonText
  buttonUrl
  fallbackImage {
    url
    width
    height
    description
    contentType
  }
  cloudinaryFallbackImage
`
export const ComponentWhatYouGetModuleFragment = `
  __typename
  sectionId
  display
  headline
  subHead
  whatYouGetCardsCollection(limit: 6) {
    items {
      ...on ComponentWhatYouGetCard {
        ${ComponentWhatYouGetCardFragment}
      }
    }
  }
`
export const ComponentSingleUseFragment = `
  title
  sectionId
  type
`

export const ComponentReviewsFragment = `
  internalLabel
  sectionId
  ctaText
	ctaUrl
`

export const FeaturedReviewsFragment = `
  heading
  testimonialsCollection(limit: 50) {
    items {
      ${TestimonialCardFragment}
    }
  }
  background {
    url
    width
    height
    description
    contentType
  }
  cloudinaryBackground
  sectionId
  titleStyle
`

export const AdvisorReviewsFragment = `
style
advisorReviewsCollection(limit: 10) {
  items {
    mediaAsset
    mobilePreviewImage
    mobileMediaAsset
    review
    eyebrowText
    advisor {
      title
      slug
      firstName
      lastName
      advisorVariant
      image {
        url
        description
        width
        height
      }
      cloudinaryImage
      shortDescription
    }
  }
}
`
export const ComponentTwoColInfographicFragment = `
  title
  sectionId
  teaserCopy {
    json
  }
  ctaText
  ctaUrl
  headingOne
  copyOne
  headingTwo
  copyTwo
  headingThree
  copyThree
  urlTarget
`

export const ComponentForaLogoFragment = `
  image {
    url
    width
    height
    description
  }
  cloudinaryImage
  url
  alignment
`

export const ComponentStickyHeaderFragment = `
  textPosition
  bgColor
  ctaUrl
  ctaText
  text
  leftLink
  icon {
    url
    description
    width
    height
  }
`

export const ComponentAdvisorPlanRowFragment = `
  title
  valueOne
  valueTwo
  valueThree
`

export const ComponentAdvisorPlanSectionFragment = `
  header
  rowsCollection(limit: 10) {
    items {
      ${ComponentAdvisorPlanRowFragment}
    }
  }
`

export const ComponentAdvisorPlanTableFragment = `
  sectionId
  sectionsCollection(limit: 10) {
    items {
      ${ComponentAdvisorPlanSectionFragment}
    }
  }
`

export const ComponentCommissionCarouselItemFragment = `
  eyebrow
  commissionAmount
  subCopy
  cloudinaryImage
`

export const ComponentCommissionCarouselFragment = `
  header	
  sectionId
  itemsCollection {
    items {
      ${ComponentCommissionCarouselItemFragment}
    }
  }
`

export const eventFragment = `
  name
  timeZone
  startDate
  startTime
  eventDuration
  eventDescription{
    json
  }
  cloudinaryBannerImage
  bannerImage{
    url
    description
    width
    height
  }
  presentersCollection {
    items {
      ...on Team {
        ${TeamCardFragment}
      }
    }
  }
  hideEvent
  autoSelectLatestReplay
  sequelEventId
  slug
`

export const ComponentThreeColumnInfographic = `
  sectionId
  headerOne
  subCopyOne
  headerTwo
  subCopyTwo
  headerThree
  subCopyThree
  subscriptionCard {
    annualPriceDetails
    monthlyPriceDetails
    bulletPoints
    monthlyOriginalPrice
    annualOriginalPrice
  }
`

export const ComponentRecommendationGridFragment = `
  sys {
    id
  }
  optionalHeader
  initialNumberOfRowsToShow
  initialNumberOfRowsToShowTablet
  initialNumberOfRowsToShowMobile
`

export const PinnedContentCollectionFragment = `
  total
  items {
    ...on BlogPosts {
      sys {
        id
      }
      __typename
      slug
      title
      eyebrow:blogCategory
      image:cloudinaryFeaturedImage
    }
    ...on DestinationsCategories {
      sys {
        id
      }
      __typename
      title
      slug
      image:cloudinaryFeaturedImage
    }
    ...on DestinationsSubcategories {
      sys {
        id
      }
      __typename
      title
      slug
      image:cloudinaryFeaturedImage
      generateLandingPage
    }
    ...on TravelBySubcategories {
      sys {
        id
      }
      __typename
      title
      slug
      image:cloudinaryFeaturedImage
      generateLandingPage
    }
    ...on TravelByCategories {
      sys {
        id
      }
      __typename
      title
      slug
      image:cloudinaryFeaturedImage
    }
    ...on Hotels {
      sys {
        id
      }
      __typename
      title
      slug
      heroImage
      generateLandingPage
      foraSupplier
    }
    ...on EntryTripReport {
      sys {
        id
      }
      __typename
      slugId
      title
      slug
      image:cloudinaryImage
    }
    ...on Itineraries {
      sys {
        id
      }
      __typename
      slugId
      title
      slug
      image:cloudinaryImage
    }
    ...on TemplateAboutUs {
      sys {
        id
      }  
    __typename
      title
    }
    ...on TemplateBooking {
      sys {
        id
      }  
    __typename  
      title
    }
    ...on TemplateCareers {
      sys {
        id
      }  
    __typename  
      title
    }
    ...on TemplateContactUs {
      sys {
        id
      }  
    __typename  
      title
    }
    ...on TemplateDestinations {
      sys {
        id
      }  
    __typename  
      title
    }
    ...on TemplateForaReserve {
      sys {
        id
      }  
    __typename  
      title
    }
    ...on TemplateHome {
      sys {
        id
      }  
    __typename  
      title
    }
    ...on TemplateJoinUs {
      sys {
        id
      }  
    __typename  
      title
    }
    ...on TemplateNewsletter {
      sys {
        id
      }  
    __typename  
      title
    }
    ...on TemplatePartners {
      sys {
        id
      }  
    __typename  
      title
    }
    ...on TemplateReviews {
      sys {
        id
      }  
    __typename  
      title
    }
    ...on TemplateWhyBookWithFora {
      sys {
        id
      }  
    __typename  
      title
    }
  }
`

export const EppoExperimentFragment = `
  eppoExperiment {
    flagKey
    controlVariation {
      __typename
      sys {
        id
      }
    }
    treatmentVariationsCollection(limit: 100) {
      items {
        __typename
        sys {
          id
        }
      }
    }
  }
`

export const CampaignPageFragment = `
title
slug
jotformId
noindexNofollow
${MetaContentFragment}
showHeader
hideFooter
hideGlobalBanner
collapseHeader
collapseFooter
isComponentLibraryPage
content {
  json
  links {
    entries {
      block {
        __typename
        sys {
          id
        }
        ...on PagesPressBanner{
          ${PressBannerFragment}
        }
        ...on PagesHeroBlock {
          ${HeroBlockFragment}
        }
        ...on PagesLearnMoreBlock {
          ${LearnMoreBlockFragment}
        }
        ...on PagesPerksList {
          ${PerksListFragment}
        }
        ...on PagesTwoColumnCta {
          ${TwoColumnCTAFragment}
        }
        ...on PagesFeaturedHotels {
          ${FeaturedHotelsFragment}
        }
      }
    }
  }
}
`

export const PageToPageAbTestFragment = `
  title
  slug
  ${EppoExperimentFragment}
`

export const ComponentEppoExperimentFragment = `
  __typename
  sys {
    id
  }
  title
  ${EppoExperimentFragment}
`

export const componentFragmentMap = {
  componentFeaturedArticles: componentFeaturedArticlesFragment,
  componentInfoGrid: ComponentInfoGridFragment,
  componentCarouselSection: CarouselSectionFragment,
  componentComparisonTable: CompareTableFragment,
  componentCtaBanner: CtaBannerFragment,
  faqsQuestionGroups: QuestionGroupFragment,
  pagesHeaderBlock: HeaderBlockFragment,
  pagesHeroBlock: HeroBlockFragment,
  componentFeaturedAdvisors: ComponentsFeaturedAdvisorsFragment,
  componentFeaturedGuides: ComponentsFeaturedGuidesFragment,
  componentForm: FormsFragment,
  componentHeroCentered: HeaderBlockWithImageFragment,
  componentHeroFullWidth: FullWidthHeroFragment,
  componentImageGalleryCarousel: ComponentImageGalleryCarouselFragment,
  pagesLearnMoreBlock: LearnMoreBlockFragment,
  componentPartnerGrid: PartnerGridFragment,
  pagesPerksList: PerksListFragment,
  componentPictureSectionBanner: PictureSectionBannerFragment,
  componentSpacer: SpacerFragment,
  componentSubNavBar: SubNavBarFragment,
  componentSubscriptionCard: SubscriptionCardFragment,
  componentTeamSection: TeamSectionFragment,
  componentTestimonialCarousel: TestimonialCarouselFragment,
  pagesTwoColumnCta: TwoColumnCTAFragment,
  componentTwoColumnTextSection: TwoColumnTextSectionFragment,
  componentVideoSection: VideoContentBlockFragment,
  pagesFeaturedHotels: FeaturedHotelsFragment,
  pagesPartnerBanner: PartnersBannerFragment,
  pagesPressBanner: PressBannerFragment,
  componentTextSection: TextSectionFragment,
  componentAnimatedTiles: AnimatedTilesFragment,
  componentThreeIconSectionWithPicture:
    ComponentThreeIconSectionWithPictureFragment,
  componentButtonCta: ComponentButtonCtaFragment,
  componentImageSideBySide: ImageSideBySideFragment,
  componentTypeformEmbed: TypeformEmbedFragment,
  componentWhatYouGetModule: ComponentWhatYouGetModuleFragment,
  componentDataBanner: ComponentDataBannerFragment,
  componentSingleUse: ComponentSingleUseFragment,
  componentReviews: ComponentReviewsFragment,
  componentFeaturedReviews: FeaturedReviewsFragment,
  componentAdvisorReviews: AdvisorReviewsFragment,
  componentTwoColumnInfographic: ComponentTwoColInfographicFragment,
  componentForaLogo: ComponentForaLogoFragment,
  componentStickyHeader: ComponentStickyHeaderFragment,
  componentAdvisorPlanTable: ComponentAdvisorPlanTableFragment,
  componentThreeColumnInfographic: ComponentThreeColumnInfographic,
  componentCommissionCarousel: ComponentCommissionCarouselFragment,
  componentRecommendationGrid: ComponentRecommendationGridFragment,
  componentEppoExperiment: ComponentEppoExperimentFragment,
}
