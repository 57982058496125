import cx from 'classnames'
import {
  Hotels,
  Maybe,
  PagesFeaturedHotels,
} from 'types/generated/contentful-types'
import { ContentfulData } from 'types/util'
import { Hotel, HotelProps } from '../itineraries/Hotel'
import { RichTextContent } from '../design/RichTextContent'
import ForaSwiper from '../swiper/ForaSwiper'
import styles from '../swiper/components/featured-hotels.module.css'

type PagesFeaturedHotelsWithoutId = Omit<PagesFeaturedHotels, '_id'>

export type FeaturedHotelsProps =
  ContentfulData<PagesFeaturedHotelsWithoutId> & {
    spacingBetween?: boolean
    spacingBelow?: boolean
    displayCount?: number
    headingSize?: 'h1' | 'h2' | 'h3' | undefined
    richHeadline?: any
    display?: string | undefined | null
    showTitle?: boolean | undefined | null
    headerAlignment?: string | undefined | null
  }

const FeaturedHotels = ({
  title,
  teaserCopy,
  hotelsCollection,
  spacingBelow = true,
  displayCount = -1,
  display,
  showTitle = true,
  headerAlignment = 'center',
}: FeaturedHotelsProps): JSX.Element => {
  const center = headerAlignment === 'center'
  const hotels =
    displayCount < 0
      ? hotelsCollection?.items.filter((item) => item)
      : hotelsCollection?.items.filter((item) => item).slice(0, displayCount)

  const sectionClass = `featured-hotels grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 lg:gap-8 ${
    spacingBelow
      ? 'pb-8 mb-8 __fora-shared-block-element lg:pb-22 lg:mb-22'
      : ''
  }`

  const renderedContent = (
    <>
      {showTitle && (
        <h2
          className={cx({
            'm-auto fora-text-h3 mb-8': true,
            // 'md:mr-18': display === 'carousel',
            'text-center': center,
            'text-left': !center,
          })}
        >
          {title}
        </h2>
      )}

      {teaserCopy && (
        <div
          className={`mb-4 lg:mb-12 page-blocks-page-content max-w-[568px] m-auto ${
            center ? 'text-center' : 'text-left'
          }`}
        >
          <RichTextContent richText={teaserCopy.json} />
        </div>
      )}

      {display === 'carousel' ? (
        <ForaSwiper
          className={styles.foraSwiper}
          params={{
            navigation: true,
            pagination: true,
            keyboard: true,
            slidesPerView: 1.2,
            spaceBetween: 16,
            centeredSlides: false,
            breakpoints: {
              1024: {
                slidesPerView: 3,
                spaceBetween: 24,
                pagination: false,
              },
            },
          }}
        >
          {hotels &&
            hotels
              .filter((item) => item)
              .map((hotel, i) => {
                return (
                  <div key={`Hotel_${i}`}>
                    <Hotel {...(hotel as HotelProps)} showImage={true} />
                  </div>
                )
              })}
        </ForaSwiper>
      ) : (
        <section className={sectionClass}>
          {hotels?.map((hotel: Maybe<Hotels>, i: number) => (
            <div key={`HotelCard_${i}`} className="hotel-card">
              <Hotel {...(hotel as Hotels)} />
            </div>
          ))}
        </section>
      )}
    </>
  )

  return renderedContent
}

export default FeaturedHotels
