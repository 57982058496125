import { Widget } from '@typeform/embed-react'
import { getCookie } from 'cookies-next'
import { useRouter } from 'next/router'
import { ComponentTypeformEmbed } from 'types/generated/contentful-types'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useEffect, useState } from 'react'
import {
  getReferralHistory,
  getURLParameters,
  trackConversionEvent,
  TrackConversionEventParams,
} from 'analytics/ForaAnalytics'

/* Embed React Widget documentation reference: https://www.typeform.com/developers/embed/configuration/ */

type ComponentTypeformEmbedProps = Omit<
  ComponentTypeformEmbed,
  'contentfulMetadata' | 'sys' | '_id'
> & {
  additionalHiddenFields?: Record<string, any>
  customStyle?: {
    width?: string
    height?: string
    border?: string
    borderRadius?: string
  }
}
export default function TypeformEmbed({
  form,
  sectionId,
  typeformFallbackUrl,
  customStyle,
  additionalHiddenFields = {},
}: ComponentTypeformEmbedProps): JSX.Element {
  const router = useRouter()
  const [pageReferralHistory] = useLocalStorage<string[]>('page-referrals', [])
  const [fbpCookie, fbcCookie, ttpCookie, hubspotCookie] = [
    getCookie('_fbp'),
    getCookie('_fbc'),
    getCookie('_ttp'),
    getCookie('hubspotutk'),
  ]
  const [clientIp, setClientIp] = useState<string | undefined>(undefined)
  const [conversionEvent, setConversionEvent] = useState<
    TrackConversionEventParams | undefined
  >(undefined)

  useEffect(() => {
    const getClientIp = async () => {
      const { foraLastSlug1, foraLastSlug2, foraLastSlug3 } =
        getReferralHistory(pageReferralHistory)

      const response = await fetch('/api/ip')
      const parsedResponse = await response.json()
      const { ip } = parsedResponse
      setClientIp(ip)

      setConversionEvent({
        componentName: 'Typeform Embed',
        fbc: fbcCookie?.toString() || '',
        fbp: fbpCookie?.toString() || '',
        hubspotCookie: hubspotCookie?.toString() || '',
        ttp: ttpCookie?.toString() || '',
        foraLastSlug1,
        foraLastSlug2,
        foraLastSlug3,
        ip,
        label: 'Typeform Submission',
        pagePath: router.pathname,
      } as unknown as TrackConversionEventParams)
    }

    getClientIp()
  }, [])

  const onSubmit = (event: any, foraEvent: TrackConversionEventParams) => {
    const { responseId, formId } = event
    const { fbclid, gclid, ttclid, msclkid, referral } = getURLParameters(
      router.query
    )

    foraEvent.typeformResponseId = responseId
    foraEvent.typeformFormId = formId
    foraEvent.fbclid = fbclid
    foraEvent.gclid = gclid
    foraEvent.ttclid = ttclid
    foraEvent.msclkid = msclkid
    foraEvent.referral = referral

    trackConversionEvent(foraEvent)
  }

  if (
    (typeof form !== 'string' && typeof typeformFallbackUrl !== 'string') ||
    !router.isReady
  )
    return <></>

  return (
    <section id={sectionId || ''} className="h-full">
      <Widget
        id={form || typeformFallbackUrl || ''}
        hidden={{
          utm_source: (router.query?.utm_source as string) || '',
          utm_medium: (router.query?.utm_medium as string) || '',
          utm_campaign: (router.query?.utm_campaign as string) || '',
          utm_term: (router.query?.utm_term as string) || '',
          utm_content: (router.query?.utm_content as string) || '',
          fbp: fbpCookie?.toString() || '',
          fbc: fbcCookie?.toString() || '',
          ttp: ttpCookie?.toString() || '',
          fora_hubspotutk: hubspotCookie?.toString() || '',
          gclid: (router.query?.gclid as string) || '',
          ttclid: (router.query?.ttclid as string) || '',
          msclkid: (router.query?.msclkid as string) || '',
          fora_lastslug1:
            pageReferralHistory[pageReferralHistory.length - 2] || '',
          fora_lastslug2:
            pageReferralHistory[pageReferralHistory.length - 3] || '',
          fora_ip: clientIp || '',
          ...additionalHiddenFields,
        }}
        style={{
          width: '100%',
          height: customStyle?.height || '700px',
          border: 'solid 1px #827363',
          borderRadius: '8px',
        }}
        className="tf-custom"
        onSubmit={(event) =>
          onSubmit(event, conversionEvent as TrackConversionEventParams)
        }
      />
    </section>
  )
}
