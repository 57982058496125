export type AdvisorExpertiseListProps = {
  expertise: string[]
  numberToShow?: number
  compact?: boolean
}

export function AdvisorExpertiseList({
  expertise,
  numberToShow,
}: AdvisorExpertiseListProps): JSX.Element {
  const expertiseList = numberToShow
    ? expertise.slice(0, numberToShow)
    : expertise
  const moreItems = expertise.length - expertiseList.length

  return (
    <ul className="expertise-list">
      {expertiseList.map((item: string, i: number) => {
        const isLastItem = i + 1 === expertiseList.length
        return (
          <li className="inline" key={`Expertise_${i}`}>
            {item}
            {!isLastItem && `, `}
          </li>
        )
      })}
      {moreItems > 0 && `, +${moreItems} more`}
    </ul>
  )
}

export function AdvisorTextExpertiseList({
  expertise,
  numberToShow,
  compact,
}: AdvisorExpertiseListProps): JSX.Element {
  const expertiseList = numberToShow
    ? expertise.slice(0, numberToShow)
    : expertise
  const moreItems = expertise.length - expertiseList.length

  return (
    <ul className="capitalize fora-text-h6">
      {expertiseList.map((item: string, i: number) => {
        const isLastItem = i + 1 === expertiseList.length
        return (
          <p
            className={`inline fora-text-${compact ? 'h5' : 'h6'}`}
            key={`Expertise_${i}`}
          >
            {item}
            {!isLastItem && `, `}
          </p>
        )
      })}
      {moreItems > 0 && `, +${moreItems} more`}
    </ul>
  )
}
