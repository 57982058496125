import { ButtonThemeWrapper } from '@/components/ui/ButtonElements'

type ButtonWrapperProps = {
  children: React.ReactNode
  buttonFn: (...args: any[]) => void
  isDisabled: boolean
  isFull?: boolean
}

export type ButtonProps = {
  showIcon?: boolean
  text: string
  theme: 'primary' | 'secondary' | 'transparent' | 'light' | 'dark'
  buttonFn: (...args: any[]) => void
  isDisabled: boolean
  isFull?: boolean
}

function ButtonWrapper({
  children,
  buttonFn,
  isDisabled,
  isFull = false,
}: ButtonWrapperProps): JSX.Element {
  const clickHandler = () => {
    if (!isDisabled) {
      buttonFn()
    }
  }
  return (
    <button
      className={`${isFull ? 'w-full h-full' : ''}`}
      onClick={clickHandler}
    >
      {children}
    </button>
  )
}

export function Button({
  showIcon,
  text,
  theme,
  isDisabled,
  buttonFn,
  isFull = false,
}: ButtonProps): JSX.Element {
  return (
    <ButtonWrapper isFull={isFull} isDisabled={isDisabled} buttonFn={buttonFn}>
      <ButtonThemeWrapper
        theme={theme}
        showIcon={showIcon}
        isDisabled={isDisabled}
        isFull={isFull}
      >
        <span className="px-6 py-3 uppercase">{text}</span>
      </ButtonThemeWrapper>
    </ButtonWrapper>
  )
}
