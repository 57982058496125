import { createContext, useContext, useState } from 'react'

interface ReviewsContextProps {
  averageRating: number
  setAverageRating: (averageRating: number) => void
  totalCount: number
  setTotalCount: (totalCount: number) => void
  sectionId: string
  setSectionId: (sectionId: string) => void
}
export const ReviewsContext = createContext<ReviewsContextProps>({
  averageRating: 0,
  setAverageRating: () => {
    return
  },
  totalCount: 0,
  setTotalCount: () => {
    return
  },
  sectionId: '',
  setSectionId: () => {
    return
  },
})
export const useReviewsContext = () => {
  const context = useContext(ReviewsContext)
  if (context === undefined) {
    throw new Error(
      'useReviewsContext must be used within a ReviewsContextProvider'
    )
  }
  return context
}

export const ReviewsContextProvider = ({ children }) => {
  const [averageRating, setAverageRating] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [sectionId, setSectionId] = useState('')
  return (
    <ReviewsContext.Provider
      value={{
        averageRating,
        setAverageRating,
        totalCount,
        setTotalCount,
        sectionId,
        setSectionId,
      }}
    >
      {children}
    </ReviewsContext.Provider>
  )
}
