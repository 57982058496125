import Image from 'next/image'
import { ContentfulImage } from '@/utils/ImageLoaders'
import { ButtonLink } from '@/components/ui/ButtonLinks'
import { ComponentThreeIconSectionWithPicture as ComponentThreeIconSectionWithPictureTypes } from 'types/generated/contentful-types'
import { LearnMoreColumn } from './LearnMoreBlock'
import { useEffect, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import useBreakpoint from 'hooks/useBreakpoints'
import { isLgPlus, isMobile } from '@/utils/Helpers'
import { isReqImage } from 'types/util'

type ComponentThreeIconSectionWithPictureProps = Omit<
  ComponentThreeIconSectionWithPictureTypes,
  'contentfulMetadata' | 'sys'
> & {
  customClass?: string
  buttonStyle?: 'primary' | 'secondary' | 'transparent'
  isH3?: boolean
}

export default function ComponentThreeIconSectionWithPicture({
  title,
  headerImage,
  mobileHeaderImage = null,
  imageColumnOne,
  imageColumnTwo,
  imageColumnThree,
  textColumnOne,
  textColumnTwo,
  textColumnThree,
  ctaText,
  ctaUrl,
  sectionId,
  customClass,
  urlTarget,
  isH3 = false,
  animate,
  separator,
  separatorMobileDisplay,
}: ComponentThreeIconSectionWithPictureProps): JSX.Element {
  const [animationStep, setAnimationStep] = useState(animate ? 0 : 6)
  const [isVisible, setVisible] = useState(false)
  const breakpoint = useBreakpoint()
  const lgPlus = isLgPlus(breakpoint)
  const mobile = isMobile(breakpoint)

  useEffect(() => {
    const onScroll = () => {
      const component = document.getElementById('firstIcon') as HTMLElement
      const componentPosition =
        component && component.getBoundingClientRect()
          ? component.getBoundingClientRect().top
          : 0
      const scrollPosition = window.innerHeight
      if (componentPosition < scrollPosition) {
        setVisible(true)
      } else {
        setVisible(false)
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isVisible && animationStep < 6) {
        setAnimationStep((prev) => prev + 1)
      }
    }, 150)
    return () => {
      clearInterval(intervalId)
    }
  }, [isVisible, animationStep])

  const transitionStyles = {
    entering: { transition: `500ms`, opacity: 0 },
    entered: { transition: `500ms`, opacity: 100 },
    exiting: { transition: `500ms`, opacity: 0 },
    exited: { transition: `500ms`, opacity: 0 },
  }
  const nodeRefOne = useRef(null)
  const separatorClass =
    separatorMobileDisplay === 'rotate'
      ? 'rotate-90 mb-6 md:mb-0 md:rotate-0 '
      : 'hidden md:block'

  return (
    <section
      id={sectionId ?? undefined}
      className={`three-icon-image text-center pb-13 md:pb-16 border-0 ${
        customClass ? customClass : ''
      }`}
    >
      <div className="relative w-full mb-3 header-image lg:mb-5">
        {isReqImage(headerImage) && !mobile && (
          <ContentfulImage asset={headerImage} />
        )}
        {mobile && (
          <Image
            src={mobileHeaderImage?.[0].secure_url || headerImage?.url}
            width={343}
            height={342}
            layout="responsive"
            alt="Fora"
          />
        )}
        <div
          className={`${
            headerImage ? 'absolute' : ''
          } top-0 flex items-center justify-center w-full z-10 h-full`}
        >
          <h2
            className={`${
              headerImage ? 'text-white' : 'pt-16'
            } lg:max-w-4xl mx-auto mb-4 fora-text-h3`}
            dangerouslySetInnerHTML={{
              __html: title ?? '',
            }}
          />
        </div>
      </div>
      <div className="md:px-12">
        <div className="mt-12 lg:flex lg:justify-center lg:gap-x-18 md:mt-17">
          <div className="w-auto">
            <div
              className="flex flex-col gap-8 md:flex-row justify-evenly justify-items-center md:gap-6"
              id={`${animate ? 'firstIcon' : ''}`}
            >
              <Transition
                nodeRef={nodeRefOne}
                in={animationStep > 1}
                timeout={250}
              >
                {(state) => (
                  <div style={transitionStyles[state]}>
                    <LearnMoreColumn
                      text={textColumnOne?.json}
                      image={imageColumnOne}
                      isCompact={false}
                      isH3={isH3}
                      customWidth="max-w-[248px]"
                    />
                  </div>
                )}
              </Transition>
              <Transition
                nodeRef={nodeRefOne}
                in={animationStep > 2}
                timeout={250}
              >
                {(state) => (
                  <div
                    style={transitionStyles[state]}
                    className={separatorClass}
                  >
                    {separator && separator.url && (
                      <Image
                        width={lgPlus ? 60 : 48}
                        height={lgPlus ? 60 : 48}
                        src={separator.url}
                        alt={separator.description || 'arrow'}
                      />
                    )}
                  </div>
                )}
              </Transition>
              <Transition
                nodeRef={nodeRefOne}
                in={animationStep > 3}
                timeout={250}
              >
                {(state) => (
                  <div style={transitionStyles[state]}>
                    <LearnMoreColumn
                      text={textColumnTwo?.json}
                      image={imageColumnTwo}
                      isCompact={false}
                      isH3={isH3}
                      customWidth="max-w-[248px]"
                    />
                  </div>
                )}
              </Transition>
              <Transition
                nodeRef={nodeRefOne}
                in={animationStep > 4}
                timeout={250}
              >
                {(state) => (
                  <div
                    style={transitionStyles[state]}
                    className={separatorClass}
                  >
                    {separator && separator.url && (
                      <Image
                        width={lgPlus ? 60 : 48}
                        height={lgPlus ? 60 : 48}
                        src={separator.url}
                        alt={separator.description || 'arrow'}
                      />
                    )}{' '}
                  </div>
                )}
              </Transition>
              <Transition
                nodeRef={nodeRefOne}
                in={animationStep > 5}
                timeout={250}
              >
                {(state) => (
                  <div style={transitionStyles[state]}>
                    <LearnMoreColumn
                      text={textColumnThree?.json}
                      image={imageColumnThree}
                      isCompact={false}
                      isH3={isH3}
                      customWidth="max-w-[248px]"
                    />{' '}
                  </div>
                )}
              </Transition>
            </div>
          </div>
        </div>
        {ctaUrl && ctaText && (
          <div className="mt-8 lg:mt-14">
            <ButtonLink
              href={ctaUrl ?? ''}
              target={(urlTarget as '_self' | '_blank') || '_self'}
              text={ctaText ?? ''}
              theme="transparent"
            />
          </div>
        )}
      </div>
      <style jsx>{`
        .three-icon-image {
          background-color: #efe9e3;
        }
      `}</style>
    </section>
  )
}
