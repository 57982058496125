import {
  Asset,
  ComponentImage as ComponentImageTypes,
} from 'types/generated/contentful-types'
import { ArrayOneOrMore } from 'types/util'
import { CloudinaryAsset } from '../cloudinary/Types'
import Image from 'next/image'

export enum ImagePositionForMobile {
  DEFAULT = 'top',
  ALTERNATE = 'bottom',
}

export type ComponentImageProps = ComponentImageTypes & {
  file?: null | Asset
  cloudinaryImage?: ArrayOneOrMore<CloudinaryAsset> | null
  width?: number
  height?: number
  key?: string
}

export default function ComponentImage({
  file,
  cloudinaryImage,
  altText,
  imageCredit,
  width,
  height,
  key,
}: ComponentImageProps): JSX.Element {
  return (
    <div key={key} className="mb-8">
      <div className="lg:h-[637px] h-[497px]">
        <Image
          src={cloudinaryImage?.[0]?.secure_url ?? file?.url ?? ''}
          data-src={cloudinaryImage?.[0]?.secure_url ?? file?.url ?? ''}
          alt={altText || ''}
          quality={90}
          width={cloudinaryImage?.[0]?.width ?? file?.width ?? width}
          height={cloudinaryImage?.[0]?.height ?? file?.height ?? height}
          style={{ objectFit: 'cover', height: '100%' }}
          priority
        />
      </div>
      {imageCredit && (
        <div className="lg:my-5">
          <p className="text-center fora-text-caption-1 !text-stone">
            {imageCredit}
          </p>
        </div>
      )}
    </div>
  )
}
