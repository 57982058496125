import { fetchContent } from '@/utils/FetchContent'
import {
  EntryTestimonial,
  EntryTestimonialCollection,
  EntryTestimonialOrder,
} from 'types/generated/contentful-types.d'

import { compact } from './Helpers'
import {
  REVIEWS_LIMIT,
  ReviewsTestimonialFragment,
} from '@/components/pageBlocks/ReviewsData'

export type CustomCache = { key: any; data: any }[]

/* ---------------------------------------------------- Reviews ---------------------------------------------------- */

export const formatStarsIn = (starsIn: number[]) =>
  starsIn.length === 0 ? [1, 2, 3, 4, 5] : [...starsIn].sort()

export type ReviewsSWRKey = [
  string,
  number,
  number,
  EntryTestimonialOrder,
  number[],
  string
]
export const generateReviewsSWRKey = (
  skip: number,
  limit: number,
  order: EntryTestimonialOrder,
  starsIn: number[],
  slug: string
): ReviewsSWRKey => [
  '/api/reviews',
  skip,
  limit,
  order,
  formatStarsIn(starsIn),
  slug,
]

const filterByStars = (
  entries: EntryTestimonial[],
  starsIn: number[]
): EntryTestimonial[] => {
  return entries.filter(
    (entry) => typeof entry.stars === 'number' && starsIn.includes(entry.stars)
  )
}

const prepareTestimonials = (
  entries: EntryTestimonial[],
  skip: number,
  limit: number,
  total: number
): EntryTestimonialCollection => {
  return {
    items: entries.slice(skip, skip + limit),
    limit: limit,
    skip: skip,
    total: total,
  }
}

const populateCache = (
  customCache: CustomCache,
  sample: EntryTestimonialCollection,
  order: EntryTestimonialOrder,
  slug: string
) => {
  const entries = compact(sample.items)
  if (!entries) return

  for (let i = 0; i < entries.length; i += REVIEWS_LIMIT) {
    const skip = i
    const starsIn = [1, 2, 3, 4, 5]
    const total = sample.total
    const key = generateReviewsSWRKey(
      skip,
      REVIEWS_LIMIT,
      order,
      formatStarsIn(starsIn),
      slug
    )
    const data = prepareTestimonials(entries, skip, REVIEWS_LIMIT, total)
    if (data.items.length >= REVIEWS_LIMIT)
      customCache.push({
        key: key,
        data,
      })
  }
}

export const fetchTestimonialCache = async (slug: string) => {
  /* consolidate queries? */
  const data = await fetchContent<{
    [alias: string]: EntryTestimonialCollection
  }>(`
      {
        sample: entryTestimonialCollection(
          limit: ${REVIEWS_LIMIT * 4}, 
          where: { display: true }, 
          order: [${EntryTestimonialOrder.DateSubmittedDesc}]
        ) {
          ${ReviewsTestimonialFragment}
          total
        }
      }
    `)

  const customCache: CustomCache = []
  if (data?.sample) {
    populateCache(
      customCache,
      data?.sample,
      EntryTestimonialOrder.DateSubmittedDesc,
      slug
    )
  }
  return customCache
}

/* ----------------------------------------------------------------------------------------------------------------- */
