import { AdvisorSignUpForm } from './AdvisorSignUpForm'
import { ForaConversionComponentNames } from 'analytics/ForaAnalytics'

export type SubscriptionFormProps = {
  title?: string
  teaserCopy?: string
  conversionComponentName: ForaConversionComponentNames
}

const SubscriptionForm = ({
  title,
  teaserCopy,
}: SubscriptionFormProps): JSX.Element => {
  return (
    <>
      <div className="text-center bg-shell">
        <h2 className="max-w-xl pb-9 fora-text-h4 text text-[48px]">{title}</h2>
        <p className="max-w-xl pb-6 m-auto fora-text-body-1">{teaserCopy}</p>
        <div className="max-w-xl m-auto">
          <AdvisorSignUpForm
            conversionComponentName="Subscription Form"
            buttonText="GET STARTED"
          />
        </div>
      </div>
    </>
  )
}

export default SubscriptionForm
