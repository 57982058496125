import Image from 'next/image'
import { marked } from 'marked'
import { dateParserMonthAndYear } from '@/utils/Helpers'
import {
  getFirstNameWithLastInitial,
  getTestimonialDetail,
} from '../testimonial/utils'
import { EntryTestimonial } from 'types/generated/contentful-types.d'
import {
  compatibleCloudinaryImageLoader,
  imageLoader,
} from '@/utils/ImageLoaders'
import { RichTextContent } from '../design/RichTextContent'
import { StarColors, Stars } from '../testimonial/Stars'
import Link from 'next/link'

const Detail = ({
  children,
  icon,
  iconAlt,
}: {
  children: React.ReactNode
  icon: string
  iconAlt: string
}): JSX.Element => (
  <li className="flex items-center gap-2" data-name="review-detail">
    {/* The line-height here corresponds to the line-height of .testimonial-detail, so the image can align with the first line of text */}
    <div className="fora-text-h8 h-[1.4em] min-w-[16px] flex items-center">
      <Image
        loader={({ src }) =>
          imageLoader({
            src: src,
            width: 16,
            quality: 90,
          })
        }
        src={icon}
        alt={iconAlt}
        width={16}
        height={16}
      />
    </div>
    <span className="testimonial-detail">{children}</span>
  </li>
)

type ReviewCardProps = EntryTestimonial & {
  parent: 'ComponentReviews' | 'FeaturedReviews' | 'AdvisorTestimonials'
}

export const ReviewCard = ({
  testimonial,
  submittedTestimonial,
  stars,
  submitterFirstName,
  submitterLastName,
  dateSubmitted,
  advisor,
  destinationsSummary,
  parent,
}: ReviewCardProps): JSX.Element => {
  const submitterFullName = getFirstNameWithLastInitial(
    submitterFirstName,
    submitterLastName
  )
  const advisorFullName = getFirstNameWithLastInitial(
    advisor?.firstName,
    advisor?.lastName
  )

  const advisorOptions = {
    ComponentReviews: advisor,
    FeaturedReviews: advisor,
    AdvisorTestimonials: null,
  }
  const nameBoxOptions = {
    ComponentReviews: 'bg-transparent',
    FeaturedReviews: 'bg-sand',
    AdvisorTestimonials: '',
  }
  const boxTitleOptions = {
    ComponentReviews: 'Travel Advisor',
    FeaturedReviews: 'Fora Advisor',
    AdvisorTestimonials: '',
  }
  const orderOptions = {
    ComponentReviews: 'order-last',
    FeaturedReviews: 'order-none',
    AdvisorTestimonials: 'order-none',
  }
  const testimonialStyleOptions = {
    ComponentReviews: '',
    FeaturedReviews: 'featured',
    AdvisorTestimonials: '',
  }
  const testimonialOptions = {
    ComponentReviews: submittedTestimonial,
    FeaturedReviews: testimonial,
    AdvisorTestimonials: submittedTestimonial,
  }
  const showImageOptions = {
    ComponentReviews: true,
    FeaturedReviews: true,
    AdvisorTestimonials: false,
  }
  const emptyStarColorOptions = {
    ComponentReviews: StarColors.DARK_SHELL,
    FeaturedReviews: StarColors.DARK_SHELL,
    AdvisorTestimonials: StarColors.SHELL,
  }
  advisor = advisorOptions[parent]
  const nameBoxStyles = nameBoxOptions[parent]
  const boxTitle = boxTitleOptions[parent]
  const orderStyles = orderOptions[parent]
  const testimonialStyle = testimonialStyleOptions[parent]
  const testimonialContent = testimonialOptions[parent]
  const showImage = showImageOptions[parent]
  const emptyStarColor = emptyStarColorOptions[parent]

  return (
    <div className="flex flex-col sm:flex-row sm:gap-8" data-name="review-card">
      {showImage && (
        <Link href={advisor ? `/advisor/${advisor.slug}` : ''} legacyBehavior>
          <a className="flex border divide-x sm:h-[max-content] sm:divide-x-0 sm:flex-col sm:divide-y sm:w-min border-blackSand divide-blackSand">
            <div className="flex-grow-0 w-28 h-28 sm:w-44 sm:h-44">
              {advisor?.cloudinaryImage && (
                <Image
                  loader={() =>
                    compatibleCloudinaryImageLoader({
                      public_id: advisor?.cloudinaryImage?.[0].public_id || '',
                      width: 256,
                      height: 256,
                      quality: 90,
                      fit: 'fill',
                    })
                  }
                  src={advisor?.cloudinaryImage[0].secure_url}
                  data-src={advisor?.cloudinaryImage[0].secure_url}
                  alt={`Advisor - ${advisorFullName}`}
                  width={256}
                  height={256}
                  quality={90}
                  objectFit="cover"
                  sizes="112px, (min-width: 1024px) 176px"
                />
              )}
            </div>
            <p
              className={`flex flex-col items-center justify-center flex-grow px-1 py-4 sm:h-[max-content] ${nameBoxStyles}`}
            >
              <span className="block mb-2 text-center uppercase whitespace-nowrap fora-text-eyebrow-4">
                {boxTitle}
              </span>
              <span className="block text-center fora-text-h6 md:fora-text-h7">
                {advisorFullName}
              </span>
            </p>
          </a>
        </Link>
      )}

      <div className={`flex flex-col gap-4 ${showImage ? 'mt-4 sm:mt-0' : ''}`}>
        {typeof stars === 'number' && (
          <Stars
            rating={stars}
            gap={4}
            emptyColor={emptyStarColor}
            showEmptyStars
          />
        )}

        <div
          className={`testimonial ${testimonialStyle} sm:order-none ${orderStyles}`}
          data-name="testimonial-text"
        >
          {testimonialContent && typeof testimonialContent === 'object' && (
            <RichTextContent richText={testimonialContent.json} />
          )}
          {testimonialContent && typeof testimonialContent === 'string' && (
            <div
              className="global-richtext"
              dangerouslySetInnerHTML={{
                __html: marked.parse(testimonialContent),
              }}
            />
          )}
        </div>

        <ul
          className={`${
            parent === 'FeaturedReviews' ? '' : 'sm:space-y-[3.6px]'
          }`}
        >
          {(submitterFirstName || submitterLastName) && (
            <Detail
              icon="https://media.foratravel.com/image/upload/v1715877483/user_o9cfko.svg"
              iconAlt="User icon"
            >
              {submitterFullName}
            </Detail>
          )}
          {destinationsSummary && (
            <Detail
              icon="https://media.foratravel.com/image/upload/v1715877470/map-pin_x1klso.svg"
              iconAlt="Map pin icon"
            >
              <div className="testimonial-detail">
                <RichTextContent
                  richText={getTestimonialDetail({
                    destinationsSummary,
                    advisor,
                  })}
                />
              </div>
            </Detail>
          )}
          {dateSubmitted && (
            <Detail
              icon="https://media.foratravel.com/image/upload/v1715948920/calendar-blank_evyxm8.svg"
              iconAlt="Calendar icon"
            >
              {dateParserMonthAndYear(dateSubmitted)}
            </Detail>
          )}
        </ul>
      </div>
    </div>
  )
}
