import { Cloudinary } from '@cloudinary/url-gen'

export const getCloudinaryUrl = (
  cloudName = 'foratravelweb',
  public_id: string
): string => {
  if (!public_id) return ''
  const cld = new Cloudinary({
    cloud: {
      cloudName,
    },
  })
  return cld.image(public_id).toURL()
}
