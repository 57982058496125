import { createContext, useContext, useState } from 'react'

interface SubNavBarContextProps {
  isPresent: boolean
  setIsPresent: (isPresent: boolean) => void
  subNavHeight: number
  setSubNavHeight: (height: number) => void
}
export const SubNavBarContext = createContext<SubNavBarContextProps>({
  isPresent: false,
  setIsPresent: () => {
    return
  },
  subNavHeight: 0,
  setSubNavHeight: () => {
    return
  },
})
export const useSubNavBarContext = () => {
  const context = useContext(SubNavBarContext)
  if (context === undefined) {
    throw new Error(
      'useSubNavBarContext must be used within a SubNavBarContextProvider'
    )
  }
  return context
}

export const SubNavBarContextProvider = ({ children }) => {
  const [isPresent, setIsPresent] = useState(false)
  const [subNavHeight, setSubNavHeight] = useState(0)
  return (
    <SubNavBarContext.Provider
      value={{
        isPresent,
        setIsPresent,
        subNavHeight,
        setSubNavHeight,
      }}
    >
      {children}
    </SubNavBarContext.Provider>
  )
}
