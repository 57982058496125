import Image from 'next/image'
import { contentfulImageLoader } from '@/utils/ImageLoaders'
import { ButtonLink } from '../ui/ButtonLinks'
import { PagesPartnerBanner } from 'types/generated/contentful-types'

export type PartnersBannerProps = PagesPartnerBanner & {
  content?: any
  isCampaign?: boolean
}

export default function PartnersBanner({
  content,
  isCampaign = false,
  logosCollection,
  showViewMoreButton,
}: PartnersBannerProps): JSX.Element {
  const partnerContent = isCampaign ? content : logosCollection?.items

  return (
    <div>
      <div
        className={`grid grid-cols-2 text-center ${
          showViewMoreButton ? 'pb-8 md:pb-12' : ''
        } items-center ${
          isCampaign ? 'md:grid-cols-3' : 'md:grid-cols-4 md:gap-6'
        }`}
      >
        {partnerContent.map((c: any, i: any) => (
          <PartnerItem key={i} partner={c} />
        ))}
      </div>
      {showViewMoreButton && (
        <div className="text-center">
          <ButtonLink
            href="/partners"
            target="_self"
            text="View More"
            theme="secondary"
          />
        </div>
      )}
    </div>
  )
}

function PartnerItem({ partner }: any): JSX.Element {
  return (
    <div className="p-6 md:p-10">
      <Image
        className=""
        loader={() =>
          contentfulImageLoader({
            src: partner.url,
            width: 315,
            height: 207,
            quality: 90,
            fit: 'scale',
          })
        }
        src={partner.url}
        alt={partner.description || ''}
        width={315}
        height={207}
        sizes="50vw"
      />
    </div>
  )
}
