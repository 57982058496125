import { ButtonLink } from './ButtonLinks'

export type ComponentButtonCtaProps = {
  internalTitle: string
  buttonOneText: string
  buttonOneStyle: string
  buttonOneUrl: string
  buttonOneUrlTarget?: '_self' | '_blank'
  buttonTwoText?: string
  buttonTwoStyle?: string
  buttonTwoUrl?: string
  buttonTwoUrlTarget?: '_self' | '_blank'
}

export default function ComponentButtonCta({
  buttonOneText,
  buttonOneStyle,
  buttonOneUrl,
  buttonOneUrlTarget,
  buttonTwoText,
  buttonTwoStyle,
  buttonTwoUrl,
  buttonTwoUrlTarget,
}: ComponentButtonCtaProps): JSX.Element {
  return (
    <>
      <div className="flex justify-center gap-4">
        <ButtonLink
          text={buttonOneText || ''}
          target={buttonOneUrlTarget || '_self'}
          href={buttonOneUrl || ''}
          theme={`${buttonOneStyle === 'solid' ? 'primary' : 'secondary'}`}
        />
        {buttonTwoText ? (
          <ButtonLink
            text={buttonTwoText}
            target={buttonTwoUrlTarget || '_self'}
            href={buttonTwoUrl || ''}
            theme={`${buttonTwoStyle === 'solid' ? 'primary' : 'secondary'}`}
          />
        ) : null}
      </div>
    </>
  )
}
