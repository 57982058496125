import Image from 'next/image'
import { RichTextContent } from '@/components/design/RichTextContent'
import { Hotels, Maybe } from 'types/generated/contentful-types'
import Link from 'next/link'
import { getCloudinaryUrl } from '@/utils/build/helpers/hotels'
import { FORA_TRAVEL_PORTAL_CLOUDINARY_WORKSPACE } from '@/utils/Constants'

export type HotelProps = Omit<Hotels, '_id'> & {
  showImage?: boolean
}

export function Hotel({
  title,
  image,
  cloudinaryImage,
  showImage = true,
  description,
  perks,
  generateLandingPage,
  slug,
  destinationCollection,
  heroImage,
  foraSupplier,
}: HotelProps): JSX.Element {
  const isLink = Boolean(
    destinationCollection &&
      destinationCollection.items.length > 0 &&
      generateLandingPage &&
      slug
  )
  return (
    <Link
      href={isLink ? `hotels/${slug}` : ''}
      className={`${
        isLink ? 'cursor-pointer' : 'cursor-default pointer-events-none'
      }`}
    >
      <section className="hotel-card" data-name="hotel-card">
        {(image ||
          cloudinaryImage?.[0] ||
          foraSupplier?.heroImage ||
          heroImage) &&
          showImage && (
            <div className="pb-2 md:pb-3">
              <Image
                className="w-[384px] md:h-[256px] h-[220px]"
                src={
                  cloudinaryImage?.[0]?.secure_url ??
                  image?.url ??
                  getCloudinaryUrl(
                    FORA_TRAVEL_PORTAL_CLOUDINARY_WORKSPACE,
                    foraSupplier?.heroImage || heroImage || ''
                  )
                }
                alt={
                  cloudinaryImage?.[0]?.metadata?.alt ??
                  image?.description ??
                  `${title}`
                }
                width={384}
                height={256}
                data-name="hotel-image"
                style={{ objectFit: 'cover' }}
              />
            </div>
          )}
        <h3
          data-name="hotel-title"
          className="pb-2 fora-text-h4 md:fora-text-h7 md:pb-3"
        >
          {title}
        </h3>
        {description?.json && (
          <div
            data-name="hotel-description"
            className="mb-6 md:mb-8 hotel-description-richtext"
          >
            <RichTextContent richText={description.json} />
          </div>
        )}
        {perks?.length && perks?.length > 0 && (
          <div>
            <h5 className="pb-2 mb-0 font-semibold uppercase fora-text-h9">
              Fora Perks
            </h5>
            <ul>
              {perks.map((perk: Maybe<string>, i: number) => {
                return (
                  <li
                    data-name={`hotel-perk-${i}`}
                    key={`Perk_${i}`}
                    className="pb-2"
                  >
                    &#9733;
                    <p className="inline pl-2 fora-text-body-2 text-darkStone">
                      {perk}
                    </p>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </section>
    </Link>
  )
}
