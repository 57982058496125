import { imageLoader } from '@/utils/ImageLoaders'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import Link from 'next/link'
import { Button } from '../ui/Buttons'
import { useMobileMenuContext } from 'context/MobileMenuContext'
import { ButtonLink } from '../ui/ButtonLinks'

export const MenuToggle = () => {
  const {
    showMobileMenu: mobileMenuExpanded,
    setShowMobileMenu: setMobileMenuExpanded,
  } = useMobileMenuContext()

  useEffect(() => {
    const body = document.querySelector('html')
    if (body) {
      mobileMenuExpanded
        ? (body.style.overflow = 'hidden')
        : (body.style.overflow = 'unset')
    }
  }, [mobileMenuExpanded])

  return (
    <button
      type="button"
      className="px-2 lg:relative left-2 focus:outline-none focus:ring-2 focus:ring-inset lg:hidden"
      aria-expanded="false"
      onClick={() => setMobileMenuExpanded(!mobileMenuExpanded)}
      onKeyDown={() => setMobileMenuExpanded(!mobileMenuExpanded)}
    >
      {mobileMenuExpanded ? (
        <div className="flex place-items-center">
          <Image
            loader={({ src }) =>
              imageLoader({
                src: src,
                width: 18,
                quality: 90,
              })
            }
            src="https://media.foratravel.com/image/upload/v1715877425/icon-menu-close_hbw0y8.svg"
            alt="Close Menu"
            width={18}
            height={18}
          />
          <span className="sr-only">Close Navigation</span>
        </div>
      ) : (
        <div className="flex place-items-center">
          <Image
            loader={({ src }) =>
              imageLoader({
                src: src,
                width: 18,
                quality: 90,
              })
            }
            src="https://media.foratravel.com/image/upload/v1715877424/icon-menu_gp6vzd.svg"
            alt="Open Menu"
            width={18}
            height={12}
          />
          <span className="sr-only">Open Navigation</span>
        </div>
      )}
    </button>
  )
}

const MobileDropdown = ({
  submenus,
  dropdown,
  setDropdown,
  level,
  levelTitle,
  prevTitle,
  levelUrl,
}: any) => {
  return (
    <div>
      <ul
        className={`transition-all px-6 duration-500 delay-150 bg-sand dropdown-mobile text-left pb-24 ${
          dropdown ? 'show translate-x-0 visible' : 'translate-x-full invisible'
        }`}
      >
        <li className="relative z-20 flex items-center px-2 py-4 text-left fora-text-navigation-1">
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown((prev) => !prev)}
            className="flex min-h-[64px] nav-btn"
          >
            <div className="flex items-center gap-x-2">
              <Image
                className="transform rotate-90"
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 10,
                    quality: 90,
                  })
                }
                src="https://media.foratravel.com/image/upload/v1715950899/dropdown_zlaoho.svg"
                alt="Next slide"
                width={10}
                height={10}
              />
              <span className="uppercase fora-text-navigation-1">
                {level === 0 ? 'All' : prevTitle}
              </span>
            </div>
          </button>
        </li>
        <Link
          href={levelUrl || '/'}
          className="block px-6 py-4 text-sans-mobile-h3-h4"
        >
          {levelTitle}
        </Link>
        {submenus.map((submenu) => (
          <MobileMenuItems
            key={submenu.name}
            link={submenu}
            level={1}
            prevTitle={levelTitle}
          />
        ))}
      </ul>
    </div>
  )
}

const MobileMenuItems = ({ link, level, prevTitle }: any) => {
  const [dropdown, setDropdown] = useState(false)

  return link.submenu && link.submenu.length > 0 ? (
    <>
      <li className="z-20 flex items-center justify-between px-6 py-3 text-left fora-text-navigation-1">
        <button
          type="button"
          aria-haspopup="menu"
          aria-expanded={dropdown ? 'true' : 'false'}
          onClick={() => setDropdown((prev) => !prev)}
          className="flex items-center flex-1 nav-btn"
        >
          <span
            className={`uppercase ${
              level === 0 ? 'text-serif-mobile-h3-h4' : 'text-serif-mobile-h4'
            }`}
          >
            {link.name}
          </span>
          <div className="flex justify-end flex-1 ml-2">
            <Image
              className="-rotate-90 "
              loader={({ src }) =>
                imageLoader({
                  src: src,
                  width: 12,
                  quality: 90,
                })
              }
              src="https://media.foratravel.com/image/upload/v1715950899/dropdown_zlaoho.svg"
              alt="Next slide"
              width={12}
              height={12}
            />
          </div>
        </button>
      </li>
      <MobileDropdown
        submenus={link.submenu}
        dropdown={dropdown}
        setDropdown={setDropdown}
        levelTitle={link.name}
        level={level}
        prevTitle={prevTitle}
        levelUrl={link.url}
      />
    </>
  ) : (
    <li
      className={`px-6 py-3 text-left uppercase  ${
        level === 0 ? 'text-serif-mobile-h3-h4' : 'text-serif-mobile-h4'
      }`}
    >
      <a href={link.url || '/'}>{link.name}</a>
    </li>
  )
}

export const MobileMenu = ({
  advisor,
  setShowingFormModal,
  expanded = true,
}: any) => {
  const {
    showMobileMenu: mobileMenuExpanded,
    updatedMenuLinks,
    setShowMobileMenu,
  } = useMobileMenuContext()
  return (
    <div
      className={`z-50 backdrop-blur-bg fixed top-[64px] left-0 w-screen h-screen text-center uppercase before:bg-sand lg:hidden ${
        mobileMenuExpanded ? 'active block' : 'inactive hidden'
      }`}
    >
      <div>
        {/* <div className="flex justify-end pr-4">
          <MenuToggle />
        </div> */}

        <ul className="relative h-[calc(100vh-64px)] p-6 pt-0 overflow-x-hidden overflow-y-auto pb-14">
          {updatedMenuLinks.map((link) => (
            <MobileMenuItems key={link.name} link={link} level={0} />
          ))}
          <li className="mt-9">
            {advisor && advisor.advisorContactEnabled ? (
              <Button
                buttonFn={() => setShowingFormModal(true)}
                text={`Book with ${advisor.firstName}`}
                theme="primary"
                isDisabled={false}
                isFull={true}
              />
            ) : (
              <ButtonLink
                href="/book-with-us"
                target="_self"
                text="Book with Fora"
                theme="primary"
                isFull
                customClass="h-[38px] md:!py-0 flex items-center"
                closeMenu={() => setShowMobileMenu(false)}
              />
            )}
          </li>
          {expanded && (
            <li className="mt-4">
              <ButtonLink
                href="/join-us"
                target="_self"
                text="Become an Advisor"
                theme="transparent"
                isFull
                customClass="h-[38px] md:!py-0 flex items-center"
                closeMenu={() => setShowMobileMenu(false)}
              />
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}
