import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { Button } from '@/components/ui/Buttons'
import { isEmailValid, isMobile } from '@/utils/Helpers'
import { getAdvisorDisplayName } from '@/utils/Helpers'
import { getCookie } from 'cookies-next'
import { useLocalStorage } from 'hooks/useLocalStorage'
import useBreakpoint from 'hooks/useBreakpoints'
import {
  ForaConversionComponentNames,
  getReferralHistory,
  getURLParameters,
  trackConversionEvent,
  mscTrackConversionEvent,
} from 'analytics/ForaAnalytics'
import Image from 'next/image'

export type AdvisorContactFormProps = {
  conversionComponentName: ForaConversionComponentNames
  title: string
  firstName?: string
  lastName?: string
  emailAddress?: string
  image?: any
  cloudinaryImage?: any
  showRightPanel?: boolean
  isDefaultAdvisor?: boolean
  onSubmit: () => void
  heading?: string
  subheading?: string
  subcopy?: string
  submitButtonText?: string
  advisorBylineEyebrow?: string
  isPartner?: string
  destinationTitle?: string
  compactVersion?: boolean
  formHelpText?: string
  useDefaultPlaceHolderText?: boolean
}

export function AdvisorContactForm({
  conversionComponentName,
  title,
  firstName = '',
  lastName = '',
  emailAddress,
  cloudinaryImage,
  showRightPanel = true,
  onSubmit,
  heading,
  subheading,
  subcopy,
  submitButtonText,
  advisorBylineEyebrow,
  isPartner = '',
  isDefaultAdvisor = false,
  destinationTitle = '',
  compactVersion = false,
  formHelpText,
  useDefaultPlaceHolderText = false,
}: AdvisorContactFormProps): JSX.Element {
  const router = useRouter()
  const [pageReferralHistory] = useLocalStorage<string[]>('page-referrals', [])
  const [emailAddressInput, updateEmailAddress] = useState<string>('')
  const [firstNameInput, updateFirstName] = useState<string>('')
  const [lastNameInput, updateLastName] = useState<string>('')
  const [commentsInput, updateComments] = useState<string>('')
  const [fbpCookie, fbcCookie, ttpCookie, hubspotCookie] = [
    getCookie('_fbp'),
    getCookie('_fbc'),
    getCookie('_ttp'),
    getCookie('hubspotutk'),
  ]
  const breakpoint = useBreakpoint()

  const displayName = getAdvisorDisplayName(
    isDefaultAdvisor ? 'us' : title,
    firstName
  )

  const isValidInput =
    isEmailValid(emailAddressInput) &&
    firstNameInput &&
    lastNameInput &&
    commentsInput

  const DEFAULT_HELP_PLACEHOLDER_TEXT =
    "I'm interested in booking a trip with you. I'm planning on traveling…"

  let formTextPlaceholder

  if (useDefaultPlaceHolderText) {
    formTextPlaceholder = DEFAULT_HELP_PLACEHOLDER_TEXT
  } else {
    formTextPlaceholder =
      formHelpText && formHelpText.length > 0
        ? formHelpText
        : isPartner
        ? `I'm interested in booking ${isPartner}. I'm planning on traveling...`
        : isDefaultAdvisor
        ? `I'm interested in booking travel to ${destinationTitle}. I'm planning on traveling...`
        : compactVersion
        ? DEFAULT_HELP_PLACEHOLDER_TEXT
        : "I'm interested in this guide... to get the conversations started, please provide some details."
  }

  const submitForm = () => {
    const { foraLastSlug1, foraLastSlug2, foraLastSlug3 } =
      getReferralHistory(pageReferralHistory)

    const {
      campaign,
      content,
      fbclid,
      gclid,
      medium,
      source,
      term,
      ttclid,
      msclkid,
    } = getURLParameters(router.query)

    fetch(`/api/forms/advisor-contact`, {
      method: 'post',
      body: JSON.stringify({
        email: emailAddressInput,
        firstName: firstNameInput,
        lastName: lastNameInput,
        comments: commentsInput,
        pageURL: router.asPath,
        advisor: title,
        advisorEmail: emailAddress,
        metadata: {
          fbclid,
          gclid,
          ttclid,
          msclkid,
          fbp: fbpCookie,
          fbc: fbcCookie,
          ttp: ttpCookie,
          hubspotutk: hubspotCookie,
          utm: {
            source,
            medium,
            campaign,
            term,
            content,
          },
        },
      }),
    }).then((response) =>
      response.json().then((data) => {
        if (data.status === 'success' && response.status === 200) {
          trackConversionEvent({
            advisorName: `${firstName} ${lastName}`,
            componentName: conversionComponentName,
            foraFormEmail: emailAddressInput,
            foraFormFirstName: firstNameInput,
            foraFormLastName: lastNameInput,
            foraFormComments: commentsInput,
            fbc: fbcCookie?.toString() || '',
            fbp: fbpCookie?.toString() || '',
            hubspotCookie: hubspotCookie?.toString() || '',
            ttp: ttpCookie?.toString() || '',
            foraLastSlug1,
            foraLastSlug2,
            foraLastSlug3,
            fbclid,
            gclid,
            msclkid,
            ip: data.request_ip,
            ttclid,
            label: 'Advisor Contact',
            pagePath: router.pathname,
          })
          mscTrackConversionEvent({ foraFormEmail: emailAddressInput })
          updateEmailAddress('')
          updateFirstName('')
          updateLastName('')
          updateComments('')
          onSubmit()
        }
      })
    )
  }

  return (
    <>
      <div className="relative grid grid-cols-12 text-left bg-shell">
        <div
          className={`${
            showRightPanel
              ? 'lg:col-span-8 max-w-2xl'
              : 'lg:col-span-12 lg:pl-20 lg:pr-20 max-w-3xl'
          } ${
            compactVersion
              ? 'lg:pr-10 lg:pt-6 lg:pb-9 px-6 pb-8 md:px-12 md:pt-6 md:max-w-full'
              : 'px-8 pb-12'
          }  pt-8 lg:pl-12 lg:pr-0 lg:pb-14 lg:pt-14 col-span-12`}
        >
          <div className="flex items-center">
            <div
              className={`hidden md:block pb-5 mx-auto mr-6 w-48 ${
                showRightPanel || compactVersion ? 'md:hidden' : ''
              }`}
            >
              {cloudinaryImage && (
                <div className="relative w-[100px] h-[100px]">
                  <Image
                    className="rounded-full"
                    src={cloudinaryImage?.[0]?.secure_url}
                    data-src={cloudinaryImage?.[0]?.secure_url}
                    alt={`${title} curated by ${title}`}
                    quality={90}
                    fill
                  />
                </div>
              )}
            </div>
            <div>
              <h4
                className={`pb-4 fora-text-h3 md:fora-text-h4 ${
                  showRightPanel ? 'lg:pb-5' : 'lg:pb-4'
                }`}
              >
                {heading ?? `Get in touch with ${displayName}`}
              </h4>
              <p className="mb-6 fora-text-body-1">
                {subheading ??
                  `Reach out to customize and book your own experience. Or, just to
                chat about travel in general.`}
              </p>
            </div>
          </div>
          <input
            className="w-full p-5 mb-5 bg-transparent border outline-none fora-text-button-2"
            type="text"
            id="advisor-contact-email"
            autoComplete="email"
            value={emailAddressInput}
            onChange={(event) => updateEmailAddress(event.target.value)}
            placeholder="*Email Address"
          />
          <div className=" lg:flex lg:gap-4">
            <input
              className="w-full p-5 mb-5 bg-transparent border outline-none lg:w-1/2 fora-text-button-2"
              type="text"
              id="advisor-contact-first-name"
              autoComplete="given-name"
              value={firstNameInput}
              onChange={(event) => updateFirstName(event.target.value)}
              placeholder="*First Name"
            />
            <input
              className="w-full p-5 mb-5 bg-transparent border outline-none lg:w-1/2 fora-text-button-2"
              type="text"
              id="advisor-contact-last-name"
              autoComplete="family-name"
              value={lastNameInput}
              onChange={(event) => updateLastName(event.target.value)}
              placeholder="*Last Name"
            />
          </div>
          <textarea
            className="w-full px-5 pt-5 pb-4 mb-5 bg-transparent border outline-none resize-none border-blackSand fora-text-button-2"
            id="advisor-contact-comments"
            value={commentsInput}
            onChange={(event) => updateComments(event.target.value)}
            placeholder={formTextPlaceholder}
            rows={isMobile(breakpoint) ? 6 : 4}
          />
          <p className="mb-6 fora-text-body-italic-3">
            {subcopy ||
              `You can normally expect a response from ${displayName} within a business day or so. You’ll also be subscribed to our travel newsletter (you can unsubscribe at any time).`}
          </p>
          <Button
            buttonFn={() => submitForm()}
            text={submitButtonText ?? `Contact ${displayName}`}
            theme="primary"
            isDisabled={!isValidInput}
          />
        </div>
        {!compactVersion && (
          <div
            className={`items-center justify-center hidden h-full col-span-4 pr-6 ml-2 border-l border-darkShell ${
              showRightPanel ? 'lg:flex' : ''
            }`}
          >
            <div className="text-center">
              <div className="w-40 pb-5 mx-auto">
                {cloudinaryImage && (
                  <div className="relative w-[160px] h-[160px]">
                    <Image
                      className="rounded-full"
                      src={cloudinaryImage?.[0]?.secure_url}
                      data-src={cloudinaryImage?.[0]?.secure_url}
                      alt={`${title} curated by ${title}`}
                      quality={90}
                      fill
                      style={{ objectFit: 'cover' }}
                    />
                  </div>
                )}
              </div>
              <p className="pb-1 uppercase fora-text-eyebrow-3">
                {advisorBylineEyebrow ?? 'Advisor'}
              </p>
              <p className="fora-text-h4">{title}</p>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
