export type CloudinaryAsset = {
  url: string
  tags: null | string[]
  type: string
  bytes: number
  width: number
  format: string
  height: number
  version: number
  duration: number | null
  metadata: Record<string, any>
  context?: { custom: { [key: string]: string } }
  public_id: string
  created_at: string
  secure_url: string
  resource_type: string
  original_url: string
  original_secure_url: string
  raw_transformation: string
}

export function isCloudinaryAsset(obj: any): obj is CloudinaryAsset {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    typeof obj.url === 'string' &&
    (obj.tags === null || Array.isArray(obj.tags)) &&
    typeof obj.type === 'string' &&
    typeof obj.bytes === 'number' &&
    typeof obj.width === 'number' &&
    typeof obj.format === 'string' &&
    typeof obj.height === 'number' &&
    typeof obj.version === 'number' &&
    (typeof obj.duration === 'number' || obj.duration === null) &&
    typeof obj.metadata === 'object' &&
    (typeof obj.context === 'undefined' ||
      (typeof obj.context === 'object' && obj.context !== null)) &&
    typeof obj.public_id === 'string' &&
    typeof obj.created_at === 'string' &&
    typeof obj.secure_url === 'string' &&
    (obj.resource_type === 'image' || obj.resource_type === 'video') &&
    typeof obj.original_url === 'string' &&
    typeof obj.original_secure_url === 'string' &&
    typeof obj.raw_transformation === 'string'
  )
}

export const isCloudinaryImage = (obj: any): obj is CloudinaryAsset => {
  return isCloudinaryAsset(obj) && obj.resource_type === 'image'
}

export const isCloudinaryVideo = (obj: any): obj is CloudinaryAsset => {
  return isCloudinaryAsset(obj) && obj.resource_type === 'video'
}

export type CloudinaryAssetsComponent = {
  title: string
  assets: CloudinaryAsset[]
}

export type FocusOnGravity =
  | 'person'
  | 'cat'
  | 'microwave'
  | 'refrigerator'
  | 'skateboard'
  | 'bird'
  | 'bottle'
  | 'dog'
  | 'sink'
  | 'face'
  | 'train'
  | 'sofa'
  | 'sheep'
  | 'pottedplant'
  | 'horse'
  | 'faces'
  | 'cow'
  | 'bus'
  | 'boat'
  | 'advancedEyes'
  | 'advancedFace'
  | 'advancedFaces'
  | 'aeroplane'
  | 'background'
  | 'bicycle'
  | 'car'
  | 'chair'
  | 'diningtable'
  | 'tvmonitor'
  | 'motorbike'
  | 'ocr'

export type CompassGravity =
  | 'auto'
  | 'north_east'
  | 'north'
  | 'north_west'
  | 'west'
  | 'south_west'
  | 'south'
  | 'south_east'
  | 'east'
  | 'center'

export type Gravity = FocusOnGravity | CompassGravity

export function isCompassGravity(gravity: string) {
  return [
    'north_east',
    'north',
    'north_west',
    'west',
    'south_west',
    'south',
    'south_east',
    'east',
    'center',
  ].includes(gravity)
}

export function isFocusOnGravity(gravity: string) {
  return [
    'person',
    'cat',
    'microwave',
    'refrigerator',
    'skateboard',
    'bird',
    'bottle',
    'dog',
    'sink',
    'face',
    'train',
    'sofa',
    'sheep',
    'pottedplant',
    'horse',
    'faces',
    'cow',
    'bus',
    'boat',
    'advancedEyes',
    'advancedFace',
    'advancedFaces',
    'aeroplane',
    'background',
    'bicycle',
    'car',
    'chair',
    'diningtable',
    'tvmonitor',
    'motorbike',
    'ocr',
  ].includes(gravity)
}

type ResizeMethod =
  | 'scale'
  | 'crop'
  | 'fit'
  | 'limit'
  | 'mfit'
  | 'fill'
  | 'lfill'
  | 'pad'
  | 'lpad'
  | 'mpad'
  | 'crop_pad'
  | 'fill_pad'
  | 'thumb'
  | 'imagga_crop'
  | 'imagga_scale'

export interface CloudinaryResizeImageProps {
  public_id?: string
  width?: number
  height?: number
  method?: ResizeMethod
  gravity?: Gravity
}

export interface CloudinarySizedImageProps extends CloudinaryResizeImageProps {
  public_id: string
  className?: string
}

export interface CloudinaryAdvancedVideoProps
  extends CloudinarySizedImageProps {
  autoPlay?: boolean
  controls?: boolean
}
