import { useEffect } from 'react'

/**
 * Prevents the browser from automatically scrolling to the previous scroll position on page load.
 */

export const useManualScrollRestoration = () => {
  useEffect(() => {
    if (
      'scrollRestoration' in history &&
      history.scrollRestoration !== 'manual'
    ) {
      history.scrollRestoration = 'manual'
    }
  }, [])
}
