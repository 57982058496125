import { dateParserMonthAndYear } from '@/utils/Helpers'
import { Advisors } from 'types/generated/contentful-types.d'

interface TestimonialInfoProps {
  testimonial: {
    submitterFirstName: string
    dateSubmitted: string
    destinationsSummary: {
      json: any
    }
  }
  advisor: {
    slug: string
    firstName: string
  }
}

/**
 * e.g.
 * Mukundha, booked travel to Thailand and Cambodia with Jane in January 2010
 */

export const getTestimonialInfo = ({
  testimonial,
  advisor,
}: TestimonialInfoProps) => {
  const { submitterFirstName, dateSubmitted, destinationsSummary } = testimonial
  const jsonResponse = {
    data: {},
    content: [
      {
        data: {},
        marks: [],
        content: [
          {
            data: {},
            marks: [],
            value: `${submitterFirstName}, booked `,
            nodeType: 'text',
            content: [
              {
                data: {},
                marks: [],
                value: '',
                nodeType: '',
              },
            ],
          },
        ],
        value: '',
        nodeType: 'paragraph',
      },
    ],
    nodeType: 'document',
  }

  destinationsSummary.json.content[0].content.map((value) => {
    jsonResponse['content'][0]['content'].push(value)
  })

  advisor &&
    jsonResponse['content'][0]['content'].push({
      data: {},
      marks: [],
      value: ' with ',
      nodeType: 'text',
      content: [],
    })

  advisor &&
    jsonResponse['content'][0]['content'].push({
      data: {
        uri: `/advisor/${advisor.slug}`,
      },
      content: [
        {
          data: {},
          marks: [],
          value: advisor.firstName,
          nodeType: 'text',
        },
      ],
      nodeType: 'hyperlink',
      marks: [],
      value: '',
    })

  dateSubmitted &&
    jsonResponse['content'][0]['content'].push({
      data: {},
      marks: [],
      content: [],
      value: ' in ',
      nodeType: 'text',
    })

  dateSubmitted &&
    jsonResponse['content'][0]['content'].push({
      data: {},
      marks: [],
      content: [],
      value: dateParserMonthAndYear(dateSubmitted),
      nodeType: 'text',
    })

  return jsonResponse
}

interface TestimonialInfo2Props {
  destinationsSummary: {
    json: any
  }
  advisor?: Advisors | null
}

/**
 * e.g.
 * Booked travel to Thailand and Cambodia with Jane S.
 */

export const getTestimonialDetail = ({
  destinationsSummary,
  advisor,
}: TestimonialInfo2Props) => {
  const advisorName = getFirstNameWithLastInitial(
    advisor?.firstName,
    advisor?.lastName
  )

  const jsonResponse = {
    data: {},
    content: [
      {
        data: {},
        marks: [],
        content: [
          {
            data: {},
            marks: [],
            value: `Booked `,
            nodeType: 'text',
            content: [
              {
                data: {},
                marks: [],
                value: '',
                nodeType: '',
              },
            ],
          },
        ],
        value: '',
        nodeType: 'paragraph',
      },
    ],
    nodeType: 'document',
  }

  destinationsSummary.json.content[0].content.map((value) => {
    jsonResponse['content'][0]['content'].push(value)
  })

  advisor &&
    advisorName &&
    jsonResponse['content'][0]['content'].push({
      data: {},
      marks: [],
      value: ' with ',
      nodeType: 'text',
      content: [],
    })

  advisor &&
    advisorName &&
    jsonResponse['content'][0]['content'].push({
      data: {
        uri: `/advisor/${advisor.slug}`,
      },
      content: [
        {
          data: {},
          marks: [],
          value: advisorName,
          nodeType: 'text',
        },
      ],
      nodeType: 'hyperlink',
      marks: [],
      value: '',
    })

  return jsonResponse
}

export const getFirstNameWithLastInitial = (
  firstName?: string | null,
  lastName?: string | null
) => `${firstName}${lastName?.charAt(0) ? ` ${lastName?.charAt(0)}.` : ''}`
