import { ButtonLink } from '../ui/ButtonLinks'
const PreviewModeBanner = () => {
  return (
    <div className="sticky top-0 z-50 flex items-center justify-center p-2 bg-blackSand opacity-90">
      <div className="flex-grow text-xs text-center text-sand">
        You are currently viewing this page in preview mode
      </div>
      <div>
        <ButtonLink
          customClass="text-xs text-center"
          theme="secondary"
          target="_self"
          href="/api/exit-preview"
          prefetch={false}
          text="Exit Preview Mode"
        />
      </div>
    </div>
  )
}

export default PreviewModeBanner
