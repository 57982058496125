import { ContentfulMetadataMock } from './ContentfulMetadataMock'

export const getPerksSection = (
  partnerName: string,
  advisorName?: string,
  partnerType = 'hotel'
) => {
  if (partnerType === 'hotel' || partnerType === 'hotels') {
    return {
      title: `What perks do you get with ${partnerName}?`,
      teaserCopy: {
        json: {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: `By booking with ${
                advisorName ? advisorName : 'Fora'
              }, you’ll automatically receive benefits at all ${partnerName} hotels & resorts worldwide, such as perks & upgrades.`,
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        links: {
          assets: { block: [], hyperlink: [] },
          entries: { block: [], hyperlink: [], inline: [] },
          resources: { block: [], hyperlink: [], inline: [] },
        },
      },
      imageColumnOne: {
        url: 'https://media.foratravel.com/image/upload/v1728049900/icon-partner-perk-2_vicpiu.svg',
        description: null,
        width: 90,
        height: 90,
        sys: { environmentId: '', id: '', spaceId: '' },
        contentfulMetadata: ContentfulMetadataMock,
      },
      imageColumnTwo: {
        url: 'https://media.foratravel.com/image/upload/v1728049788/icon-partner-perk-1_nfd4ui.svg',
        description: null,
        width: 90,
        height: 90,
        sys: { environmentId: '', id: '', spaceId: '' },
        contentfulMetadata: ContentfulMetadataMock,
      },
      imageColumnThree: {
        url: 'https://media.foratravel.com/image/upload/v1715877432/icon-partner-perk-3_letmxj.svg',
        description: null,
        width: 90,
        height: 90,
        sys: { environmentId: '', id: '', spaceId: '' },
        contentfulMetadata: ContentfulMetadataMock,
      },
      textColumnOne: {
        json: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'hotel \n$$$',
                  nodeType: 'text',
                },
              ],
              nodeType: 'heading-3',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'Hotel, dining & spa credits',
                  nodeType: 'text',
                },
              ],
              nodeType: '',
            },
          ],
          nodeType: 'document',
        },
        links: {
          assets: { block: [], hyperlink: [] },
          entries: { block: [], hyperlink: [], inline: [] },
          resources: { block: [], hyperlink: [], inline: [] },
        },
      },
      textColumnTwo: {
        json: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'The BEST \n ROOMS',
                  nodeType: 'text',
                },
              ],
              nodeType: 'heading-3',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'Free upgrades whenever possible',
                  nodeType: 'text',
                },
              ],
              nodeType: '',
            },
          ],
          nodeType: 'document',
        },
        links: {
          assets: { block: [], hyperlink: [] },
          entries: { block: [], hyperlink: [], inline: [] },
          resources: { block: [], hyperlink: [], inline: [] },
        },
      },
      textColumnThree: {
        json: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'VIP \n treatment',
                  nodeType: 'text',
                },
              ],
              nodeType: 'heading-3',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'From start to finish, we got you',
                  nodeType: 'text',
                },
              ],
              nodeType: '',
            },
          ],
          nodeType: 'document',
        },
        links: {
          assets: { block: [], hyperlink: [] },
          entries: { block: [], hyperlink: [], inline: [] },
          resources: { block: [], hyperlink: [], inline: [] },
        },
      },

      ctaText: null,
      ctaUrl: null,
      isCompact: false,
      sectionId: null,
    }
  } else {
    return {
      title: `Why Book ${partnerName} with ${
        advisorName ? advisorName : 'Fora'
      }`,
      teaserCopy: {
        json: {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: `Booking with ${
                advisorName ? advisorName : 'a Fora Advisor'
              } means VIP treatment from start to finish. Say hello to…`,
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        links: {
          assets: { block: [], hyperlink: [] },
          entries: { block: [], hyperlink: [], inline: [] },
          resources: { block: [], hyperlink: [], inline: [] },
        },
      },
      imageColumnOne: {
        url:
          partnerType === 'hotel'
            ? 'https://media.foratravel.com/image/upload/v1728049900/icon-partner-perk-2_vicpiu.svg'
            : 'https://media.foratravel.com/image/upload/v1715877384/fora_icon_bespoke_planning_afzoqz.svg',
        description: null,
        width: 90,
        height: 90,
        sys: { environmentId: '', id: '', spaceId: '' },
        contentfulMetadata: ContentfulMetadataMock,
      },
      imageColumnTwo: {
        url:
          partnerType === 'hotel'
            ? 'https://media.foratravel.com/image/upload/v1728049788/icon-partner-perk-1_nfd4ui.svg'
            : 'https://media.foratravel.com/image/upload/v1715877384/fora_icon_vip_status_mzi7dc.svg',
        description: null,
        width: 90,
        height: 90,
        sys: { environmentId: '', id: '', spaceId: '' },
        contentfulMetadata: ContentfulMetadataMock,
      },
      imageColumnThree: {
        url:
          partnerType === 'hotel'
            ? 'https://media.foratravel.com/image/upload/v1715877432/icon-partner-perk-3_letmxj.svg'
            : 'https://media.foratravel.com/image/upload/v1715877383/fora_icon_1on1_support_b4tcix.svg',
        description: null,
        width: 90,
        height: 90,
        sys: { environmentId: '', id: '', spaceId: '' },
        contentfulMetadata: ContentfulMetadataMock,
      },
      textColumnOne: {
        json: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'BESPOKE \n PLANNING',
                  nodeType: 'text',
                },
              ],
              nodeType: 'heading-3',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'Itinerary design to level up your vacation.',
                  nodeType: 'text',
                },
              ],
              nodeType: '',
            },
          ],
          nodeType: 'document',
        },
        links: {
          assets: { block: [], hyperlink: [] },
          entries: { block: [], hyperlink: [], inline: [] },
          resources: { block: [], hyperlink: [], inline: [] },
        },
      },
      textColumnTwo: {
        json: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'VIP \n STATUS',
                  nodeType: 'text',
                },
              ],
              nodeType: 'heading-3',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'Upgrades and amenities along the way.',
                  nodeType: 'text',
                },
              ],
              nodeType: '',
            },
          ],
          nodeType: 'document',
        },
        links: {
          assets: { block: [], hyperlink: [] },
          entries: { block: [], hyperlink: [], inline: [] },
          resources: { block: [], hyperlink: [], inline: [] },
        },
      },
      textColumnThree: {
        json: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: '1:1 \n SUPPORT',
                  nodeType: 'text',
                },
              ],
              nodeType: 'heading-3',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'From start to finish, we got you.',
                  nodeType: 'text',
                },
              ],
              nodeType: '',
            },
          ],
          nodeType: 'document',
        },
        links: {
          assets: { block: [], hyperlink: [] },
          entries: { block: [], hyperlink: [], inline: [] },
          resources: { block: [], hyperlink: [], inline: [] },
        },
      },

      ctaText: null,
      ctaUrl: null,
      isCompact: false,
      sectionId: null,
    }
  }
}

export const getHowToBookContent = (
  partnerName: string,
  partnerType = 'hotel'
) => {
  return {
    title: `How To Book ${partnerName}`,
    teaserCopy: {
      json: {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value:
              partnerType === 'hotel' || partnerType === 'hotels'
                ? `When you book your stay with a Fora Advisor, you’ll automatically have ${partnerName} perks applied to your stay at no extra cost.`
                : '',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
      links: {
        assets: { block: [], hyperlink: [] },
        entries: { block: [], hyperlink: [], inline: [] },
        resources: { block: [], hyperlink: [], inline: [] },
      },
    },
    imageColumnOne: {
      url: 'https://media.foratravel.com/image/upload/v1715877475/round-number-1_a39foh.svg',
      description: null,
      sys: { environmentId: '', id: '', spaceId: '' },
      contentfulMetadata: ContentfulMetadataMock,
    },
    imageColumnTwo: {
      url: 'https://media.foratravel.com/image/upload/v1715877476/round-number-2_bcqf3l.svg',
      description: null,
      sys: { environmentId: '', id: '', spaceId: '' },
      contentfulMetadata: ContentfulMetadataMock,
    },
    imageColumnThree: {
      url: 'https://media.foratravel.com/image/upload/v1715877478/round-number-3_tjyyu4.svg',
      description: null,
      sys: { environmentId: '', id: '', spaceId: '' },
      contentfulMetadata: ContentfulMetadataMock,
    },
    textColumnOne: {
      json: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'Find an advisor who gets you',
                nodeType: 'text',
              },
            ],
            nodeType: 'heading-4',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  partnerType === 'hotel'
                    ? 'Choose a Fora Advisor to plan your travel.'
                    : 'Reach out to plan your travel.',
                nodeType: 'text',
              },
            ],
            nodeType: '',
          },
        ],
        nodeType: 'document',
      },
      links: {
        assets: { block: [], hyperlink: [] },
        entries: { block: [], hyperlink: [], inline: [] },
        resources: { block: [], hyperlink: [], inline: [] },
      },
    },
    textColumnTwo: {
      json: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'Collab to book your trip',
                nodeType: 'text',
              },
            ],
            nodeType: 'heading-4',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'Share your preferences with your advisor.',
                nodeType: 'text',
              },
            ],
            nodeType: '',
          },
        ],
        nodeType: 'document',
      },
      links: {
        assets: { block: [], hyperlink: [] },
        entries: { block: [], hyperlink: [], inline: [] },
        resources: { block: [], hyperlink: [], inline: [] },
      },
    },
    textColumnThree: {
      json: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  partnerType === 'hotel' ? `Voila... Unlock perks!` : 'Voila!',
                nodeType: 'text',
              },
            ],
            nodeType: 'heading-4',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  partnerType === 'hotel'
                    ? `Automatically unlock perks at ${partnerName}.`
                    : 'Unlock VIP treatment, special upgrades & more.',
                nodeType: 'text',
              },
            ],
            nodeType: '',
          },
        ],
        nodeType: 'document',
      },
      links: {
        assets: { block: [], hyperlink: [] },
        entries: { block: [], hyperlink: [], inline: [] },
        resources: { block: [], hyperlink: [], inline: [] },
      },
    },
    ctaText: 'Find an advisor',
    ctaUrl: '/book-with-us',
    isCompact: false,
    sectionId: null,
    solidBackground: false,
  }
}

export const getDefaultAdvisor = (
  partnerName?: string,
  partnerType?: string
) => {
  return {
    title: 'Fora',
    slug: 'fora',
    advisorVariant: 'Fora',
    firstName: '',
    lastName: '',
    emailAddress: 'bookings@foratravel.com',
    subheading:
      partnerType === 'hotel'
        ? `Ready to unlock ${partnerName} perks? If you already have a Fora Advisor, reach out to them directly. Otherwise, reach out to us to customize and book your trip today.`
        : `Ready to book${
            partnerName ? ` ${partnerName}` : ''
          }? If you already have a Fora Advisor, reach out to them directly. Otherwise, reach out to us to customize and book your trip today.`,
    cloudinaryImage: [
      {
        public_id: 'default-fora-advisor-image_c5fcci',
        secure_url:
          'https://media.foratravel.com/image/upload/v1714407202/default-fora-advisor-image_c5fcci.png',
      },
    ],
    expertise: '',
    advisorBylineEyebrow: 'The Modern Travel Agency ',
    isPartner: partnerName,
  }
}
