import 'regenerator-runtime/runtime'
import { format } from 'date-fns'
import { fetchContent } from '@/utils/FetchContent'
import {
  EntryTestimonialCollection,
  Maybe,
} from 'types/generated/contentful-types.d'
import {
  ComponentReviewsProps,
  RatingCountsFragment,
} from '@/components/pageBlocks/Reviews'
import { fetchTestimonialCache } from './SWR'

export const DATETIMEFORMAT = 'YYYY/MM/DD h:mm A'

export const makeRandom = <T extends Array<any>>(items: T): T =>
  items.sort(() => Math.random() - 0.5)

export const isEmailValid = (email: string) => {
  const regExp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )

  return regExp.test(email)
}

export const is2xlPlus = (breakpoint: string) => {
  return ['2xl'].includes(breakpoint)
}

export const isXlPlus = (breakpoint: string) => {
  return ['2xl', 'xl'].includes(breakpoint)
}

export const isLgPlus = (breakpoint: string) => {
  return ['2xl', 'xl', 'lg'].includes(breakpoint)
}

export const isMdPlus = (breakpoint: string) => {
  return ['2xl', 'xl', 'lg', 'md'].includes(breakpoint)
}

export const isDesktop = (breakpoint: string) => {
  return ['2xl', 'xl', 'lg'].includes(breakpoint)
}
export const isMobile = (breakpoint: string) => {
  return ['sm', 'md'].includes(breakpoint)
}
export const isTablet = (breakpoint: string) => {
  return ['md'].includes(breakpoint)
}

export const isPhone = (breakpoint: string) => {
  return ['sm'].includes(breakpoint)
}

export const getAdvisorDisplayName = (title: string, firstName?: string) => {
  return firstName ? firstName : title
}

export const allAreNull = (arr: any[]) => {
  return arr.every((element) => element === null)
}

export const curedCategoriesWithLanding = (
  collection,
  hasChildSubCats = false
) => {
  return collection.map((d) => ({
    ...d,
    subcategoriesCollection: {
      ...d.subcategoriesCollection,
      items: hasChildSubCats
        ? d.subcategoriesCollection.items
            .map((x) => ({
              ...x,
              childSubcategoriesCollection: {
                items: x.childSubcategoriesCollection.items.filter(
                  (a) => a.generateLandingPage
                ),
              },
            }))
            .filter((sd) => sd.generateLandingPage)
        : d.subcategoriesCollection.items.filter(
            (sd) => sd.generateLandingPage
          ),
    },
  }))
}

export const dateParser = (date: string): string => {
  const dt = new Date(date)
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)
  return format(dtDateOnly, 'PP')
}

export const dateParserMonthAndYear = (date: string): string => {
  const dt = new Date(date)
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)
  return format(dtDateOnly, 'MMMM yyyy')
}

export const isObject = (value: any) => {
  return !!(value && typeof value === 'object' && !Array.isArray(value))
}

export const compact = <T>(array?: Maybe<T>[]): T[] =>
  array?.filter((item): item is T => item !== null && item !== undefined) ?? []

export const fetchChildSubcategoriesBySlug = async (
  slug: string,
  preview = false,
  env?: string
) => {
  const res = await fetchContent(
    `
    {
      destinationsSubcategoriesCollection(limit: 1, where: {slug: "${slug}"}, preview: ${preview}) {
        items {
          title
          slug
          country {
            slug
          }
          generateLandingPage
          sys {
            publishedAt
          }
          childSubcategoriesCollection(limit: 100) {
            items {
              title
              slug
              country {
                slug
              }
              generateLandingPage
              childSubcategoriesCollection(limit: 1) {
                total
              }
              sys {
                publishedAt
              }
            }
          }
        }
      }
    }
  `,
    preview,
    env
  )

  const childSubcategories = res?.destinationsSubcategoriesCollection
    ?.items?.[0]?.childSubcategoriesCollection || { items: [] }

  // iterate over the child collections and check if they have more dependencies
  for (const childSubcategory of childSubcategories.items.filter(
    (item) => item
  )) {
    if (childSubcategory?.childSubcategoriesCollection?.total > 0) {
      childSubcategory.childSubcategoriesCollection =
        await fetchChildSubcategoriesBySlug(childSubcategory.slug, preview, env)
    } else {
      childSubcategory.childSubcategoriesCollection = { items: [] }
    }
  }

  return childSubcategories
}

type ExpandDataOptions = {
  env?: string
  preview?: boolean
}
export const expandDestinationsCategoriesData = async (
  data: any,
  opts?: ExpandDataOptions
) => {
  for (const key in data) {
    if (key === 'subcategoriesCollection') {
      for (const subcategory of data[key].items.filter((item) => item)) {
        subcategory['childSubcategoriesCollection'] = {}
        await expandDestinationsCategoriesData(subcategory, opts)
      }
    } else if (key === 'childSubcategoriesCollection') {
      data[key] = await fetchChildSubcategoriesBySlug(
        data['slug'],
        opts?.preview,
        opts?.env
      )
    } else if (isObject(data[key])) {
      await expandDestinationsCategoriesData(data[key], opts)
    } else if (Array.isArray(data[key])) {
      for (let i = 0; i < data[key].length; i++) {
        await expandDestinationsCategoriesData(data[key][i], opts)
      }
    }
  }
  return data
}

export type TestimonialAggregations = {
  averageRating: number
  // Index of 0 is the count of 1 star ratings, index of 1 is the count of 2 star ratings, etc.
  counts: number[]
  totalCount: number
}
const fetchTestimonialAggregations =
  async (): Promise<TestimonialAggregations> => {
    const data = await fetchContent<{
      [alias: string]: EntryTestimonialCollection
    }>(`{${RatingCountsFragment}}`)

    const fiveStar = data?.fiveStar?.total ?? 0
    const fourStar = data?.fourStar?.total ?? 0
    const threeStar = data?.threeStar?.total ?? 0
    const twoStar = data?.twoStar?.total ?? 0
    const oneStar = data?.oneStar?.total ?? 0
    const sum =
      fiveStar * 5 + fourStar * 4 + threeStar * 3 + twoStar * 2 + oneStar * 1
    const counts = [oneStar, twoStar, threeStar, fourStar, fiveStar]
    const totalCount = oneStar + twoStar + threeStar + fourStar + fiveStar
    const averageRating = sum / totalCount
    return {
      averageRating: averageRating,
      counts: counts,
      totalCount: totalCount,
    }
  }

export const expandComponentReviewEntries = async (
  _pageComponentsCollection
) => {
  for (const component of _pageComponentsCollection.items) {
    if (component?.__typename !== 'ComponentReviews') continue
    ;(component as ComponentReviewsProps).testimonialAggregations =
      await fetchTestimonialAggregations()
    ;(component as ComponentReviewsProps).customCache =
      await fetchTestimonialCache('_pageBlock')
  }
}

export const createRouter = () => {
  return {
    pathname: '/',
    route: '/',
    query: {},
    asPath: '/',
    components: {},
    isFallback: false,
    basePath: '',
    events: {
      emit: cy.spy().as('emit'),
      off: cy.spy().as('off'),
      on: cy.spy().as('on'),
    },
    push: cy.spy().as('push'),
    replace: cy.spy().as('replace'),
    reload: cy.spy().as('reload'),
    back: cy.spy().as('back'),
    prefetch: cy.stub().as('prefetch').resolves(),
    beforePopState: cy.spy().as('beforePopState'),
    isLocaleDomain: true,
    isReady: true,
    isPreview: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    forward: () => {},
  }
}

export const sliceHour = (hour) => {
  return hour < 10 ? hour.toString().slice(0) : hour.toString()
}

export const removeLeadingZero = (timeStr) => {
  const [hoursStr, minutes, period] = timeStr.split(':')
  const hours = parseInt(hoursStr, 10)

  const formattedHours =
    hours < 10 ? hours.toString().slice(0) : hours.toString()

  return `${formattedHours}:${minutes}`
}

export const parseDateTime = (startDate, startTime) => {
  const [date, time] = startDate.split('T')
  return date + ' ' + startTime ?? time
}

export const scrollToRef = (ref: React.RefObject<HTMLElement>) => {
  if (ref.current) {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }
}
