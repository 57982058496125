import Link from 'next/link'
import Image from 'next/image'
import { imageLoader } from '@/utils/ImageLoaders'

type LogoProps = {
  width?: number
  height?: number
  loadWithPriority?: boolean
  isStamp?: boolean
  color?: 'white' | 'black'
}

export function Logo({
  width,
  color = 'black',
  loadWithPriority = false,
  isStamp = false,
}: LogoProps): JSX.Element {
  return (
    <Link
      href="/"
      className={`block relative ${
        isStamp
          ? 'w-[80px] h-[96px]'
          : 'w-[105px] md:w-[124px] md:h-[38px] h-[32px]'
      }`}
    >
      <Image
        loader={({ src }) =>
          imageLoader({
            src: src,
            width: width ?? 80,
            quality: 90,
          })
        }
        src={
          isStamp
            ? 'https://media.foratravel.com/image/upload/v1715287680/fora-website-assets/d1pfvlcv7razobmvwotx.svg'
            : color === 'black'
            ? 'https://media.foratravel.com/image/upload/v1715287680/fora-website-assets/snp9bmzrjdxxdjlivbm8.svg'
            : 'https://media.foratravel.com/image/upload/v1715287680/fora-website-assets/prvi5sjvd3zomtrsmu4b.svg'
        }
        layout="fill"
        alt="Fora - Home"
        priority={loadWithPriority}
      />
    </Link>
  )
}
