/* Define general shared constants here */

export const FORA_TRAVEL_PORTAL_CLOUDINARY_WORKSPACE = 'foratravelportal'
export const HOTEL_PAGES_TYPEFORM_URL_DEV =
  'https://foratravel.typeform.com/to/VfXtEjbE '
export const HOTEL_PAGES_TYPEFORM_URL_PROD =
  'https://foratravel.typeform.com/to/o71PSDRB '

export const KNOWN_COUNTRY_SLUGS = [
  'united-states-of-america',
  'austria',
  'mexico',
  'czech-republic',
  'japan',
  'dominican-republic',
  'thailand',
  'switzerland',
  'portugal',
  'greece',
  'germany',
  'australia',
  'italy',
  'spain',
  'scotland',
  'canada',
  'netherlands',
  'united-arab-emirates-uae',
  'ireland',
  'france',
  'england',
  'south-africa',
  'peru',
  'indonesia',
  'brazil',
  'bahamas',
  'norway',
  'iceland',
  'poland',
  'french-polynesia',
  'india',
  'tanzania',
  'croatia',
  'finland',
  'turkiye',
  'south-korea',
  'morocco',
  'jordan',
  'argentina',
  'philippines',
  'cambodia',
  'vietnam',
  'malaysia',
  'nepal',
  'new-zealand',
  'israel',
  'hungary',
  'puerto-rico',
  'colombia',
  'egypt',
  'u-s-virgin-islands',
  'kenya',
  'denmark',
  'sweden',
  'colorado',
  'florida',
  'oregon',
  'north-carolina',
  'california',
  'ecuador',
  'pakistan',
  'europe',
]
