import { AdvancedImage, AdvancedVideo } from '@cloudinary/react'
import { Cloudinary } from '@cloudinary/url-gen'
import { Resize } from '@cloudinary/url-gen/actions/resize'
import {
  CloudinarySizedImageProps,
  isFocusOnGravity,
  isCompassGravity,
  CloudinaryResizeImageProps,
  CloudinaryAdvancedVideoProps,
} from './Types'
import { autoGravity, compass } from '@cloudinary/url-gen/qualifiers/gravity'
import { focusOn } from '@cloudinary/url-gen/qualifiers/gravity'
import { FocusOn } from '@cloudinary/url-gen/qualifiers/focusOn'

export function resizeCldImg({
  width = 0,
  height = 0,
  method = 'scale',
  gravity = 'auto',
}: CloudinaryResizeImageProps) {
  const gravityAllowed = !['scale', 'fit'].includes(method)
  let resize = Resize[method]()
  if (width) {
    resize = resize.width(width)
  }
  if (height) {
    resize = resize.height(height)
  }
  if (gravityAllowed && (!gravity || gravity === 'auto')) {
    resize = resize.gravity(autoGravity())
  }
  if (gravityAllowed && isCompassGravity(gravity)) {
    resize = resize.gravity(compass(gravity))
  }
  if (gravityAllowed && isFocusOnGravity(gravity)) {
    resize = resize.gravity(focusOn(FocusOn[gravity]()))
  }
  return resize
}

export const CloudinaryAdvancedImage = ({
  public_id,
  width = 0,
  height = 0,
  method,
  gravity,
  className,
}: CloudinarySizedImageProps): JSX.Element => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'foratravelweb',
    },
  })
  let cldImg = cld.image(public_id)
  const adjustments = width || height || method || gravity
  if (adjustments) {
    cldImg = cldImg.resize(
      resizeCldImg({ width, height, method: method || 'scale', gravity })
    )
  } else {
    cldImg = cld.image(public_id).format('auto').quality('auto')
  }

  return <AdvancedImage cldImg={cldImg} className={className || ''} />
}

export const CloudinaryAdvancedVideo = ({
  public_id,
  width = 0,
  height = 0,
  method,
  gravity,
  autoPlay = false,
  controls = true,
}: CloudinaryAdvancedVideoProps): JSX.Element => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'foratravelweb',
    },
  })
  let cldVid = cld.video(public_id)
  const adjustments = width || height || method || gravity
  if (adjustments) {
    cldVid = cldVid.resize(
      resizeCldImg({ width, height, method: method || 'scale', gravity })
    )
  } else {
    cldVid = cld.video(public_id).format('auto').quality('auto')
  }

  return (
    <AdvancedVideo autoPlay={autoPlay} controls={controls} cldVid={cldVid} />
  )
}
