import Image from 'next/image'
import { ButtonLink } from '@/components/ui/ButtonLinks'
import { RichTextContent } from '@/components/design/RichTextContent'
import Link from 'next/link'
import {
  Asset,
  Maybe,
  PagesLearnMoreBlock,
} from 'types/generated/contentful-types'
import { CloudinaryAsset } from '../cloudinary/Types'

type LearnMoreColumnProps = {
  text: any
  image?: Maybe<Asset> | undefined
  cloudinaryImage?: CloudinaryAsset[]
  isCompact: boolean
  isH3?: boolean
  customWidth?: string
}

type PagesLearnMoreBlockProps = Omit<
  PagesLearnMoreBlock,
  'contentfulMetadata' | 'sys' | '_id'
> & {
  customClass?: string
  buttonStyle?: 'primary' | 'secondary' | 'transparent'
  isH3?: boolean
}

export function LearnMoreColumn({
  image,
  cloudinaryImage,
  text,
  isCompact,
  isH3,
  customWidth,
}: LearnMoreColumnProps): JSX.Element {
  return (
    <div
      className={`md:pb-8 lg:pb-0 lg:w-full m-auto md:m-0 ${
        customWidth ? customWidth : 'md:max-w-[265px]'
      }`}
    >
      {!isCompact && (
        <div className="w-12 h-12 mx-auto mb-6">
          <Image
            src={cloudinaryImage?.[0]?.secure_url ?? image?.url ?? ''}
            alt={
              cloudinaryImage?.[0]?.metadata?.alt ||
              image?.description ||
              `Fora - ${image?.url}`
            }
            width={48}
            height={48}
          />
        </div>
      )}
      {isH3 ? (
        <h3 className={`learn-more-block ${isCompact ? ' text-left' : ''}`}>
          <RichTextContent richText={text} />
        </h3>
      ) : (
        <h2 className={`learn-more-block ${isCompact ? ' text-left' : ''}`}>
          <RichTextContent richText={text} />
        </h2>
      )}
    </div>
  )
}

export default function LearnMoreBlock({
  title,
  teaserCopy,
  imageColumnOne,
  imageColumnTwo,
  imageColumnThree,
  cloudinaryImageColumnOne,
  cloudinaryImageColumnTwo,
  cloudinaryImageColumnThree,
  textColumnOne,
  textColumnTwo,
  textColumnThree,
  ctaText,
  ctaUrl,
  isCompact = false,
  sectionId,
  customClass,
  buttonStyle = 'primary',
  urlTarget,
  solidBackground,
  isH3 = false,
}: PagesLearnMoreBlockProps): JSX.Element {
  return (
    <section
      id={sectionId ?? undefined}
      className={`text-center ${customClass ? customClass : ''} ${
        solidBackground ? 'bg-shell border-0 px-4 md:px-12 py-16' : ''
      }`}
    >
      <div>
        {!isCompact && (
          <h2
            className="mx-auto mb-4 lg:max-w-4xl fora-text-h3"
            dangerouslySetInnerHTML={{
              __html: title ?? '',
            }}
          />
        )}
        {!isCompact && teaserCopy && (
          <div className="max-w-3xl m-auto mt-3 learn-more-teaser-copy">
            <RichTextContent richText={teaserCopy} />
          </div>
        )}
        <div className="mt-10 lg:flex lg:justify-center lg:gap-x-18 md:mt-6 lg:mt-17">
          {isCompact && (
            <div>
              <h4
                className="mb-6 md:mb-4 fora-text-h4"
                dangerouslySetInnerHTML={{
                  __html: title ?? '',
                }}
              />
              <Link
                href={ctaUrl ?? ''}
                className="hidden text-left underline fora-text-body-1 md:block"
              >
                {ctaText}
              </Link>
            </div>
          )}
          <div className={`${solidBackground ? 'w-full' : ''}`}>
            {isCompact && teaserCopy && (
              <div className="pb-2 text-left">
                <RichTextContent richText={teaserCopy} />
              </div>
            )}
            <div className="flex flex-col gap-9 md:flex-row justify-evenly justify-items-center">
              <LearnMoreColumn
                cloudinaryImage={cloudinaryImageColumnOne}
                text={textColumnOne?.json}
                image={imageColumnOne}
                isCompact={isCompact ?? false}
                isH3={isH3}
              />
              <LearnMoreColumn
                cloudinaryImage={cloudinaryImageColumnTwo}
                text={textColumnTwo?.json}
                image={imageColumnTwo}
                isCompact={isCompact ?? false}
                isH3={isH3}
              />
              <LearnMoreColumn
                cloudinaryImage={cloudinaryImageColumnThree}
                text={textColumnThree?.json}
                image={imageColumnThree}
                isCompact={isCompact ?? false}
                isH3={isH3}
              />
            </div>
          </div>
        </div>
        {!isCompact && ctaUrl && ctaText && (
          <div className="mt-6 lg:mt-14">
            <ButtonLink
              href={ctaUrl ?? ''}
              target={(urlTarget as '_self' | '_blank') || '_self'}
              text={ctaText ?? ''}
              theme={buttonStyle}
            />
          </div>
        )}
      </div>
    </section>
  )
}
