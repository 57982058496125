import Image from 'next/image'
import { UploadApiResponse as CloudinaryAsset } from 'cloudinary'

export type PerksListProps = {
  title: string
  titleStyle?: string
  listItems: string[]
  cloudinaryImage: [CloudinaryAsset & { metadata: { alt?: string } }]
  cloudinaryIconImage: [CloudinaryAsset & { metadata: { alt?: string } }]
  image?: {
    url: string
    description: string
  }
  iconImage?: {
    url: string
    description: string
  }
}

export default function PerksList({
  title,
  titleStyle,
  listItems,
  cloudinaryImage,
  cloudinaryIconImage,
  image,
  iconImage,
}: PerksListProps): JSX.Element {
  const altText =
    cloudinaryImage?.[0]?.metadata?.alt ||
    image?.description ||
    `Fora - ${title}`

  const iconAltText =
    cloudinaryIconImage?.[0]?.metadata?.alt || iconImage?.description || 'Icon'

  return (
    <section>
      <div>
        <div className="grid lg:grid-cols-2" data-name="perksList-wrapper">
          <div className="flex order-2 lg:w-4/5" data-name="perksList-content">
            <div className="place-self-center min-w-full">
              <div className="pb-8 lg:pb-4">
                {(cloudinaryIconImage || iconImage) && (
                  <div
                    className="block mb-3 w-12"
                    data-name="perksList-iconImage"
                  >
                    <Image
                      src={
                        cloudinaryIconImage?.[0]?.secure_url ||
                        iconImage?.url ||
                        ''
                      }
                      alt={iconAltText}
                      layout="responsive"
                      width={128}
                      height={128}
                      sizes="20vw"
                    />
                  </div>
                )}
                <h2
                  className={`pb-8 mb-4 border-b border-darkShell ${
                    titleStyle === 'Italic' ? 'fora-text-h3' : 'fora-text-h3'
                  } lg:pb-12 lg:mb-6`}
                >
                  {title}
                </h2>
                <ul className="list-disc list-outside">
                  {listItems &&
                    listItems.map((perk: string, i: number) => (
                      <span
                        key={`Perk_${i}`}
                        className="block pb-4 mb-4 border-b border-darkShell lg:pb-6 lg:mb-6"
                      >
                        <li className="ml-5 fora-text-body-1">{perk}</li>
                      </span>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          <div
            className="pb-9 lg:order-2 lg:border-none"
            data-name="perksList-image"
          >
            <Image
              className="max-h-[600px] object-cover"
              src={cloudinaryImage?.[0]?.secure_url || image?.url || ''}
              alt={altText}
              width={600}
              height={600}
              sizes="50vw"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
