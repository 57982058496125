import { createContext, useContext, useState } from 'react'

interface StickyHeaderContextProps {
  isPresent: boolean
  setIsPresent: (isPresent: boolean) => void
  stickyHeaderHeight: number
  setStickyHeaderHeight: (height: number) => void
}
export const StickyHeaderContext = createContext<StickyHeaderContextProps>({
  isPresent: false,
  setIsPresent: () => {
    return
  },
  stickyHeaderHeight: 0,
  setStickyHeaderHeight: () => {
    return
  },
})
export const useStickyHeaderContext = () => {
  const context = useContext(StickyHeaderContext)
  if (context === undefined) {
    throw new Error(
      'useStickyHeaderContext must be used within a StickyHeaderContextProvider'
    )
  }
  return context
}

export const StickyHeaderContextProvider = ({ children }) => {
  const [isPresent, setIsPresent] = useState(false)
  const [stickyHeaderHeight, setStickyHeaderHeight] = useState(0)
  return (
    <StickyHeaderContext.Provider
      value={{
        isPresent,
        setIsPresent,
        stickyHeaderHeight,
        setStickyHeaderHeight,
      }}
    >
      {children}
    </StickyHeaderContext.Provider>
  )
}
