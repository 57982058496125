import cx from 'classnames'
import { isDesktop, isMobile } from '@/utils/Helpers'
import useBreakpoint from 'hooks/useBreakpoints'
import ClientOnly from '@/utils/ClientOnly'

export const SIZES = [
  'XXS',
  'XS',
  'SM',
  'MD',
  'LG',
  'XL',
  '2XL',
  '3XL',
  '4XL',
  '5XL',
] as const

export type SpacerSize = (typeof SIZES)[number]

export interface SpacerProps {
  className?: string
  size?: SpacerSize
}

const getSpacerClassName = (size: SpacerSize): string => {
  return `spacer-${size.toLowerCase()}`
}

export const Spacer = ({ className, size = 'LG' }: SpacerProps) => {
  const spacerClassName = getSpacerClassName(size)
  const classes = cx(className, spacerClassName)
  return (
    <ClientOnly>
      <div className={classes} />
    </ClientOnly>
  )
}

export interface ResponsiveSpacerProps {
  className?: string
  size?: SpacerSize
  mobileSize?: SpacerSize
}

export const ResponsiveSpacer = ({
  className,
  size = 'LG',
  mobileSize,
}: ResponsiveSpacerProps) => {
  const breakpoint = useBreakpoint()

  return (
    <>
      {isDesktop(breakpoint) && <Spacer className={className} size={size} />}
      {isMobile(breakpoint) && (
        <Spacer className={className} size={mobileSize || size} />
      )}
    </>
  )
}
