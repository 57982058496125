export type ContentfulData<T> = Omit<T, 'contentfulMetadata' | 'sys'>

export interface ReqAsset {
  url: string
}

export type ArrayOneOrMore<T> = {
  0: T
} & Array<T>

export function isArrayOneOrMore<T>(obj: any): obj is ArrayOneOrMore<T> {
  return Array.isArray(obj) && obj.length > 0
}

export function isTypedArrayOneOrMore<T>(
  obj: any,
  typePredicate: (item: any) => item is T
): obj is ArrayOneOrMore<T> {
  return Array.isArray(obj) && obj.length > 0 && obj.every(typePredicate)
}

export interface ReqImage extends ReqAsset {
  width: number
  height: number
  description?: string
}

export function isReqImage(obj: any): obj is ReqImage {
  const fileEndings = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg']
  return (
    typeof obj === 'object' &&
    obj !== null &&
    typeof obj.url === 'string' &&
    fileEndings.some((ending) =>
      obj.url.toLowerCase().endsWith(`.${ending}`)
    ) &&
    typeof obj.width === 'number' &&
    typeof obj.height === 'number'
  )
}
