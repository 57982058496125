export enum StarColors {
  BLACK_SAND = '#131313',
  STONE = '#827363',
  DARK_SHELL = '#BDB1A4',
  SHELL = '#E5DBD0',
  SAND = '#FEFAF5',
}

const StarSvg = ({
  width,
  color,
}: {
  width: number
  color: string
}): JSX.Element => (
  <svg
    width={width}
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.58694 18.6042C4.37092 18.6042 4.1549 18.5322 3.96296 18.3882C3.57898 18.1003 3.435 17.6443 3.57898 17.1882L5.235 12.1002C5.30704 11.8842 5.235 11.6202 5.04297 11.5002L0.722972 8.35623C0.338992 8.06827 0.195012 7.61225 0.338992 7.15623C0.482976 6.70021 0.891032 6.41225 1.34695 6.41225H6.69895C6.93895 6.41225 7.15497 6.26827 7.22691 6.02827L8.88293 0.940269C9.02692 0.484249 9.43497 0.196289 9.89089 0.196289C10.3469 0.196289 10.7549 0.484249 10.8989 0.940269L12.5309 6.05207C12.6029 6.26809 12.8189 6.43605 13.0589 6.43605H18.4109C18.8909 6.43605 19.2748 6.72401 19.4188 7.18003C19.5628 7.63605 19.4188 8.09207 19.0348 8.38003L14.7148 11.524C14.5228 11.668 14.4509 11.908 14.5228 12.124L16.1788 17.212C16.3228 17.6681 16.1788 18.1241 15.7948 18.412C15.4109 18.7 14.9309 18.7 14.5469 18.412L10.2269 15.268C10.0348 15.124 9.77086 15.124 9.57892 15.268L5.25892 18.412C5.01892 18.5321 4.8029 18.6041 4.58688 18.6041L4.58694 18.6042Z"
      fill={color}
    />
  </svg>
)

const Star = ({
  percent,
  width,
  color,
  emptyColor,
}: {
  percent: number
  width: number
  color: StarColors
  emptyColor: StarColors
}): JSX.Element => {
  if (percent <= 0) {
    return <StarSvg color={emptyColor} width={width} />
  } else if (percent >= 100) {
    return <StarSvg color={color} width={width} />
  } else {
    return (
      <div className="relative grid" style={{ height: `${width}px` }}>
        <StarSvg color={emptyColor} width={width} />
        <div
          className="absolute top-0 left-0 grid overflow-hidden"
          style={{ width: `${percent}%` }}
        >
          <StarSvg color={color} width={width} />
        </div>
      </div>
    )
  }
}

interface StarsProps {
  rating: number
  width?: number
  gap?: number
  color?: StarColors
  emptyColor?: StarColors
  showEmptyStars?: boolean
}

export const Stars = ({
  rating,
  width = 20,
  gap = 8,
  color = StarColors.BLACK_SAND,
  emptyColor = StarColors.DARK_SHELL,
  showEmptyStars = false,
}: StarsProps): JSX.Element => {
  const wholeStars = Math.floor(rating)
  const fractionStar = rating - wholeStars
  const length = showEmptyStars ? 5 : wholeStars

  return (
    <div
      className="grid justify-center grid-rows-1 w-max text-stone"
      style={{
        gridTemplateColumns: `repeat(${length}, minmax(0, 1fr))`,
        columnGap: `${gap}px`,
        height: `${width}px`,
      }}
      aria-label={`${rating} stars`}
      data-name="star-rating"
    >
      {Array.from({ length }).map((_, index) => {
        if (index < wholeStars) {
          return (
            <Star
              key={index}
              percent={100}
              width={width}
              color={color}
              emptyColor={emptyColor}
            />
          )
        } else if (index === wholeStars) {
          return (
            <Star
              key={index}
              percent={fractionStar * 100}
              width={width}
              color={color}
              emptyColor={emptyColor}
            />
          )
        } else {
          return (
            <Star
              key={index}
              percent={0}
              width={width}
              color={color}
              emptyColor={emptyColor}
            />
          )
        }
      })}
    </div>
  )
}
