import { ButtonLink } from '@/components/ui/ButtonLinks'
import Link from 'next/link'

export type AdvisorSignUpSuccessModalProps = {
  displayName: string
  onClose?: () => void
}

export function AdvisorSignUpSuccessModal({
  displayName,
  onClose,
}: AdvisorSignUpSuccessModalProps): JSX.Element {
  return (
    <div className="z-50 px-12 py-12 text-center lg:px-14 bg-shell lg:py-14">
      <p className="pb-8 fora-text-h4">
        Your message has been sent to {displayName}!
      </p>
      <p className="mb-8 fora-text-body-1">
        Learn more about{' '}
        <Link
          href="/the-journal/what-can-i-expect-working-with-a-travel-agent-your-questions-answered"
          className="underline"
        >
          what to expect when working with an advisor.
        </Link>
      </p>
      <ButtonLink
        href="/"
        target="_self"
        text="Close"
        theme="primary"
        isModal={true}
        onClose={onClose}
      />
    </div>
  )
}
