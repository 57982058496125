import { useEffect, useLayoutEffect } from 'react'

/**
 * Users should only experience smooth scrolling when navigating to locations within the same page via internal links.
 * Page to page navigation should not involve smooth scrolling as it can disorient users
 */
const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect

export const useSmoothScrollOnSamePageLinksOnly = () => {
  useIsomorphicLayoutEffect(() => {
    // Set the scroll behavior to 'auto' before the first paint
    document.documentElement.style.scrollBehavior = 'auto'

    // Avoids scrollbar layout shifting
    document.documentElement.style.scrollbarGutter = 'stable'

    // Set it to 'smooth' after the first paint
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        document.documentElement.style.scrollBehavior = 'smooth'
      })
    })

    return () => {
      document.documentElement.style.scrollBehavior = 'auto'
    }
  }, [])

  // If the user prefers reduced motion, disable smooth scrolling
  return (
    <>
      <style jsx global>{`
        @media (prefers-reduced-motion: reduce) {
          html {
            scroll-behavior: auto;
            scrollbar-gutter: stable;
          }
        }
      `}</style>
    </>
  )
}
