import Image from 'next/image'
import cx from 'classnames'
import { ButtonLink } from '@/components/ui/ButtonLinks'
import { RichTextContent } from '@/components/design/RichTextContent'
import { PagesTwoColumnCta } from 'types/generated/contentful-types'
import Form, { FormProps } from './Forms'
import { compact } from '@/utils/Helpers'

type MediaProps = Pick<
  PagesTwoColumnCtaProps,
  'cloudinaryImage' | 'image' | 'title'
> & {
  fillLayout?: boolean
}

function Media({ cloudinaryImage, image, title }: MediaProps): JSX.Element {
  if (!cloudinaryImage?.[0]?.secure_url && !image?.url) return <></>

  const hasVideo =
    cloudinaryImage?.[0]?.resource_type === 'video' ||
    image?.contentType === 'video/mp4'

  return hasVideo ? (
    <video
      autoPlay={true}
      muted={true}
      loop={true}
      playsInline
      controls={false}
      className="object-cover object-center absolute inset-0 w-full h-full"
    >
      <source src={cloudinaryImage?.[0]?.secure_url ?? image?.url} />
    </video>
  ) : (
    <Image
      src={cloudinaryImage?.[0]?.secure_url || image?.url}
      alt={
        cloudinaryImage?.[0]?.metadata?.alt ||
        image?.description ||
        `Fora - ${title}`
      }
      quality={90}
      fill
      style={{ objectFit: 'cover' }}
    />
  )
}

type BodyProps = Pick<
  PagesTwoColumnCtaProps,
  'textBlockLeft' | 'perksCollection'
> & {
  smallSize: boolean
  fullSize: boolean
}

const Body = ({
  smallSize,
  fullSize,
  textBlockLeft,
  perksCollection,
}: BodyProps): JSX.Element => {
  const perksItems = compact(perksCollection?.items)

  if (perksItems.length > 0) {
    return (
      <ul className="pb-8 list-disc list-outside border-b fora-text-body-1 border-darkShell">
        {perksItems.map((perk, i) => {
          return (
            <>
              <span
                key={`Perk_Space_${i}`}
                className="block pt-6 mt-6 list-none border-t first:mt-2 lg:first:mt-4 border-darkShell"
                aria-hidden
              />
              <li
                key={`Perk_${i}`}
                className={`two-columns-perks-richtext ${
                  perk.image?.url ? 'list-none flex' : 'ml-5'
                }`}
              >
                {perk.image?.url && (
                  <div className="mr-4 w-6">
                    <Image
                      src={perk.image?.url ?? ''}
                      alt={perk.image?.description ?? ''}
                      layout="responsive"
                      width={24}
                      height={24}
                      sizes="24px"
                    />
                  </div>
                )}
                <div className="w-11/12">
                  {perk.header && (
                    <p
                      className={
                        perk.titleStyle === 'Italic'
                          ? 'fora-text-h6'
                          : 'fora-text-h6'
                      }
                      data-name="perk-header"
                    >
                      {perk.header}
                    </p>
                  )}
                  {perk.subheading && (
                    <p className="fora-text-body-1" data-name="perk-copy">
                      {perk.subheading}
                    </p>
                  )}
                </div>
              </li>
            </>
          )
        })}
      </ul>
    )
  } else {
    return (
      <div
        className={cx({
          'cta-richtext': true,
          small: smallSize,
          full: fullSize,
        })}
      >
        {textBlockLeft && <RichTextContent richText={textBlockLeft} />}
      </div>
    )
  }
}

export enum TwoColumnCtaBgColor {
  SHELL = 'shell',
  TRANSPARENT = 'transparent',
  BLACK_SAND = 'blackSand',
}

export enum TwoColumnCtaSize {
  SMALL = 'Small',
  FULL = 'Full',
  DEFAULT = 'Default',
}

export type PagesTwoColumnCtaProps = Omit<
  PagesTwoColumnCta,
  'contentfulMetadata' | 'sys' | '_id'
> & {
  reverse?: boolean
  destinationClass?: boolean
  jotformId?: string
  secondaryButtonUrl?: string
  secondaryButtonText?: string
  mobileAlignment?: 'left' | 'center'
}

export default function TwoColumnCTA({
  title,
  titleStyle,
  textBlockLeft,
  mobileAlignment = 'left',
  image,
  cloudinaryImage,
  buttonText,
  buttonUrl,
  urlTarget,
  buttonTheme,
  backgroundColor,
  contentColumn = 'left',
  textUnderneathButton,
  formId,
  jotformId,
  reverse = true,
  sectionId,
  secondaryButtonUrl,
  secondaryButtonText,
  destinationClass,
  cardSize,
  perksCollection,
  titleIcon,
  cloudinaryTitleIcon,
  advisor,
  advisorDescription,
  yMargin,
}: PagesTwoColumnCtaProps): JSX.Element {
  const smallSize = cardSize === TwoColumnCtaSize.SMALL
  const fullSize = cardSize === TwoColumnCtaSize.FULL
  const defaultSize = !smallSize && !fullSize
  const centerMobile = mobileAlignment === 'center'
  const shell = backgroundColor === TwoColumnCtaBgColor.SHELL
  const blackSand = backgroundColor === TwoColumnCtaBgColor.BLACK_SAND
  const transparent = !shell && !blackSand
  const solidBackground = shell || blackSand
  yMargin = yMargin ?? 0
  yMargin = yMargin < 0 ? 0 : yMargin
  yMargin = Math.floor(yMargin)

  return (
    <section className="grid place-content-center place-items-center">
      <style jsx>{`
        [data-name='content-col-inner'] {
        }
        @media screen and (min-width: 1024px) {
          [data-name='content-col-inner'] {
            margin-top: ${yMargin}px;
            margin-bottom: ${yMargin}px;
          }
        }
      `}</style>
      <div
        id={sectionId || ''}
        className={cx({
          'w-full': true,
          'lg:w-screen lg:max-w-[1920px]': fullSize,
          'm-auto max-w-[780px]': smallSize,
          'bg-shell': shell,
          'bg-transparent': transparent,
          'bg-darkSand': blackSand,
        })}
      >
        <div>
          <div
            className="grid lg:grid-cols-2 lg:items-center"
            data-name="twoColumnCta-layout"
          >
            <div
              className={`${reverse ? 'order-2' : 'order-1'} ${
                contentColumn === 'right' ? 'lg:order-2' : 'lg:order-1'
              } lg:h-full lg:flex lg:items-center ${smallSize ? 'p-14' : ''}`}
              data-name="content-col"
            >
              <div
                data-name="content-col-inner"
                className={cx({
                  'flex flex-col': true,
                  'px-6 py-10 lg:px-14 lg:py-8': solidBackground && defaultSize,
                  'px-6 py-16 md:px-22': solidBackground && fullSize,
                  'py-8': transparent,
                  'lg:py-14': transparent && (defaultSize || fullSize),
                  'lg:pl-0 lg:pr-28': transparent && contentColumn === 'left',
                  'lg:pr-0 lg:pl-28': transparent && contentColumn === 'right',
                  'text-center lg:text-left items-center lg:items-start':
                    centerMobile,
                  'text-left': !centerMobile,
                })}
              >
                {(cloudinaryTitleIcon?.[0]?.secure_url || titleIcon?.url) && (
                  <div className="block mb-3 w-12" data-name="title-icon">
                    <Image
                      src={
                        cloudinaryTitleIcon?.[0]?.secure_url ?? titleIcon?.url
                      }
                      alt={
                        cloudinaryTitleIcon?.[0]?.metadata.alt ||
                        titleIcon?.description ||
                        'Icon'
                      }
                      width={128}
                      height={128}
                    />
                  </div>
                )}
                {title && (
                  <h2
                    className={cx({
                      'mb-6 lg:mb-8': true,
                      'fora-text-h4': smallSize && titleStyle !== 'Italic',
                      'fora-text-h3': defaultSize && titleStyle !== 'Italic',
                      'fora-text-h2': fullSize && titleStyle !== 'Italic',
                    })}
                  >
                    {title}
                  </h2>
                )}
                {advisor && advisor.cloudinaryImage && (
                  <div className="mb-6 lg:mb-12">
                    <div className="flex pb-2 lg:pb-3">
                      <div className="relative mr-6 w-12 h-12">
                        <Image
                          className="rounded-full"
                          src={advisor?.cloudinaryImage?.[0]?.secure_url}
                          alt={`Advisor - ${advisor.title}`}
                          quality={90}
                          fill
                          style={{ objectFit: 'cover' }}
                          data-name="advisor-image"
                          data-src={advisor?.cloudinaryImage?.[0]?.url}
                        />
                      </div>
                      <div className="h-full">
                        <h3 className="fora-text-body-italic-3">
                          Fora Advisor
                        </h3>
                        <h2
                          data-name="advisor-title"
                          className="fora-text-h5 md:fora-text-h7"
                        >
                          {advisor.title}
                        </h2>
                      </div>
                    </div>
                    <h6
                      className="fora-text-body-3"
                      data-name="advisor-description"
                    >
                      {advisorDescription}
                    </h6>
                  </div>
                )}
                <Body
                  smallSize={smallSize}
                  fullSize={fullSize}
                  textBlockLeft={textBlockLeft}
                  perksCollection={perksCollection}
                />
                <div className="flex flex-col gap-2 w-max md:flex-row md:w-auto">
                  {buttonUrl && buttonText && (
                    <ButtonLink
                      href={buttonUrl ?? ''}
                      target={
                        (urlTarget as '_self' | '_blank' | undefined) || '_self'
                      }
                      text={buttonText ?? ''}
                      theme={
                        (buttonTheme as
                          | 'primary'
                          | 'secondary'
                          | 'transparent') || 'primary'
                      }
                      destinationClass={destinationClass}
                    />
                  )}

                  {secondaryButtonUrl && secondaryButtonText && (
                    <ButtonLink
                      href={secondaryButtonUrl ?? ''}
                      target={
                        (urlTarget as '_self' | '_blank' | undefined) || '_self'
                      }
                      text={secondaryButtonText ?? ''}
                      theme="transparent"
                    />
                  )}
                </div>
                {textUnderneathButton && (
                  <div className="pt-6 md:pt-8 text-underneath-button">
                    <RichTextContent richText={textUnderneathButton} />
                  </div>
                )}
                {formId && (
                  <div className="pt-6">
                    <Form
                      {...({
                        formId,
                        jotformId,
                        buttonText,
                        conversionComponentName: 'Two Column CTA',
                      } as unknown as FormProps)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div
              className={`h-full lg:pb-0 ${reverse ? 'order-1' : 'order-2'} ${
                contentColumn === 'right' ? 'lg:order-1' : 'lg:order-2'
              }`}
              data-name="img-col"
            >
              <div className="relative pb-[100%] h-full">
                <Media
                  cloudinaryImage={cloudinaryImage}
                  image={image}
                  title={title}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
