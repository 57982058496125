import { useState } from 'react'
import TypeformEmbed from '../pageBlocks/TypeformEmbed'
import { AdvisorSignUpForm } from './AdvisorSignUpForm'
import { ComponentForm } from 'types/generated/contentful-types'
import PricingModels from '../common/PricingModels'
import { ForaConversionComponentNames } from 'analytics/ForaAnalytics'

export type GetOnWaitlistProps = Pick<
  ComponentForm,
  'title' | 'teasercopy' | 'buttonText' | 'successText'
> & {
  typeformUrl?: string
  zapierId?: string
  subscriptionCard?: any
  conversionComponentName: ForaConversionComponentNames
  typeformPopOutUrl?: string
}

const GetOnWaitlist = ({
  title,
  teasercopy,
  buttonText,
  typeformUrl,
  zapierId,
  subscriptionCard,
  conversionComponentName,
  successText,
  typeformPopOutUrl = '',
}: GetOnWaitlistProps): JSX.Element => {
  const typeformStyle = {
    height: '100%',
  }
  const [showTypeform, setShowTypeform] = useState<boolean>(false)
  return (
    <div className="relative overflow-hidden overflow-y-scroll lg:overflow-y-auto">
      <div
        className={`p-8 h-auto text-center  bg-shell lg:px-20 items-center lg:py-[120px] lg:p-0 transition-all duration-500 lg:flex lg:justify-between ${
          subscriptionCard ? 'md:text-left' : ''
        } ${
          showTypeform
            ? 'translate-x-[-100%] opacity-0 h-[600px] md:h-auto'
            : 'opacity-1'
        }`}
      >
        <div className={subscriptionCard ? '' : 'w-full'}>
          <h2 className="max-w-xl pb-4 m-auto lg:pb-5 fora-text-h3">
            {title || 'Ready to transform your passion for travel into $$$?'}
          </h2>
          <p className="max-w-xl pb-6 m-auto fora-text-body-1">
            {teasercopy ||
              'Earn income by planning and booking travel. Sign up to become a Fora Advisor today.'}
          </p>
          <div className="max-w-xl m-auto">
            <AdvisorSignUpForm
              conversionComponentName={conversionComponentName}
              buttonText={buttonText || 'GET STARTED'}
              successMessage={
                successText ||
                'Thanks! Check your inbox for a survey to get off the waitlist.'
              }
              typeformUrl={typeformUrl}
              typeformPopOutUrl={typeformPopOutUrl}
              setShowTypeform={setShowTypeform}
              jotformId={zapierId}
              hasSubscriptionCard
            />
          </div>
        </div>

        {subscriptionCard && (
          <div className="hidden md:block">
            <PricingModels {...subscriptionCard} />
          </div>
        )}
      </div>
      {typeformUrl && (
        <div
          className={`absolute top-0 left-0 w-full h-full transition-all duration-500 ${
            showTypeform
              ? 'translate-x-0 opacity-1'
              : 'translate-x-[100%] opacity-0'
          }`}
        >
          <TypeformEmbed
            typeformFallbackUrl={typeformUrl}
            customStyle={typeformStyle}
          />
        </div>
      )}
    </div>
  )
}

export default GetOnWaitlist
